<kaz-default-frame>
  <div class="flex flex-col gap-8 text-sm text-[#222222] pb-6">
    <span class="font-semibold text-xl">Nutzungsbedingung</span>
    <div class="flex flex-col gap-4">
      <span>kleinanzeigen.bo.de ist eine Webseite der Reiff Verlag GmbH & Co. KG, Marlener Straße 9, 77656 Offenburg, Deutschland. Diese Nutzungsbedingungen regeln das Vertragsverhältnis zwischen der Reiff Verlag GmbH & Co. KG und den Nutzern, welche die Dienstleistungen der Reiff Verlag GmbH & Co. KG in Anspruch nehmen. Diese Nutzungsbedingungen gelten ab dem 1. Juni 2016.</span>
      <span>Sofern Sie als Verbraucher handeln, haben Sie folgendes Widerrufsrecht:</span>
    </div>
    <span class="font-semibold text-xl">WIDERRUFSBELEHRUNG</span>


    <div class="flex flex-col gap-4">
      <span class="font-semibold text-base">Widerrufsrecht</span>
      <span>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. </span>
      <span>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Reiff Verlag GmbH & Co. KG, Marlener Straße 9, 77656 Offenburg, Deutschland, Faxnummer: +49 781 / 504-16 09, E-Mail: <a
        class="underline text-[#F0811A]" href="mailto:leserservice@reiff.de">leserservice@reiff.de</a>)  mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</span>

      <span class="font-semibold text-base">Widerrufsfolgen</span>
      <span>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</span>

      <span class="font-semibold text-base">§ 1 Registrierung und Nutzerkonto</span>
      <ol role="list" class="list-decimal marker:text-[#F0811A] pl-5">
        <li>Die Nutzung der Website kleinanzeigen.bo.de setzt keine Registrierung voraus. Erst bei einer Anzeigenaufgabe
          oder beim Wunsch zur Kontaktaufnahme mit dem Verkäufer wird eine Registrierung erforderlich. Dies dient dem
          Schutz des Verkäufers vor anonymen Anfragen. Mit der Aufgabe einer Anzeige kommt zwischen der Reiff Verlag
          GmbH &amp; Co.&nbsp;KG und&nbsp;dem Nutzer ein Vertrag über die Nutzung Website kleinanzeigen.bo.de (im
          Folgenden: "Nutzungsvertrag") zustande. Ein Anspruch auf Abschluss eines Nutzungsvertrags besteht nicht.
        </li>
        <li>Die von der Reiff Verlag GmbH &amp; Co.&nbsp;KG bei der Registrierung abgefragten Daten sind vollständig und
          korrekt anzugeben. Die Registrierung erfolgt unter Angabe einer gültigen E-Mail-Adresse und der Wahl eines
          persönlichen Passworts. Das persönliche Passwort ist geheim zu halten und darf nicht an Dritte weitergegeben
          werden.
        </li>
        <li>Der Dienst richtet sich aussschließlich an private Nutzer. Gewerblich Anbieter kontaktieren bitte unseren
          Anzeigenaußendienst.
        </li>
        <li>Ein Nutzer kann den Nutzungsvertrag jederzeit kündigen. Dazu genügt eine E-Mail an den Kundenservice oder
          schriftlich an: Reiff Verlag GmbH &amp; Co.&nbsp;KG, Marlener Straße 9, 77656 Offenburg, Deutschland. Die
          Reiff Verlag GmbH &amp; Co.&nbsp;KG kann den Nutzungsvertrag jederzeit mit einer Frist von 14 Tagen kündigen.
          Das Recht zur Sperrung nach § 3 bleibt davon unberührt.
        </li>
        <li>Die Reiff Verlag GmbH &amp; Co.&nbsp;KG behält sich das Recht vor, nicht vollständig angemeldete
          Nutzerkonten und Nutzerkonten, welche für einen längeren Zeitraum inaktiv waren, zu löschen.
        </li>
      </ol>

      <span class="font-semibold text-base">§ 2 Nutzung der Webseite kleinanzeigen.bo.de</span>
      <span>1. Nutzer sind verpflichtet Anzeigen (Gesuche und Angebote) in die passende Kategorie einzustellen und mit Worten und Bildern vollständig und wahrheitsgemäß zu beschreiben.</span>
      <span>2. Es ist verboten Anzeigen oder sonstige Inhalte auf kleinanzeigen.bo.de zu veröffentlichen, die gegen gesetzliche Vorschriften, Rechte Dritter oder gegen die guten Sitten verstoßen.</span>
      <span>Beachten Sie dabei, dass es verboten ist:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>gegen Gesetze und unsere&nbsp;Grundsätze&nbsp;bezüglich verbotener Inhalte zu verstoßen</li>
        <li>unehrlich oder irreführend zu handeln</li>
        <li>die Rechte Dritter zu verletzen</li>
        <li>Spam, Kettenbriefe oder Schneeballsysteme zu verteilen oder zu bewerben</li>
        <li>Viren oder andere Technologien zu versenden, die die Website kleinanzeigen.bo.de oder die Interessen bzw.
          das Eigentum von Benutzern der Website schädigen könnten
        </li>
        <li>die Infrastruktur der Website kleinanzeigen.bo.de einer übermäßigen Belastung auszusetzen oder auf andere
          Weise das problemlose Funktionieren der Website zu stören
        </li>
        <li>die Inhalte anderer Anzeigen zu kopieren, zu ändern oder zu verbreiten</li>
        <li>Roboter, Crawler, Spider, Scraper oder andere automatisierte Mechanismen zu nutzen, um&nbsp;auf die Website
          kleinanzeigen.bo.de zuzugreifen und Inhalte zu sammeln, egal zu welchem Zweck, ohne unsere ausdrückliche
          schriftliche Zustimmung
        </li>
        <li>Informationen, insbesondere E-Mail-Adressen, über andere Nutzer zu sammeln oder in irgendeiner Art sich zu
          verschaffen, ohne Einwilligung des jeweiligen Nutzers
        </li>
        <li>Maßnahmen zu umgehen, die dazu dienen, den Zugriff auf die Website kleinanzeigen.bo.de zu verhindern oder
          einzuschränken
        </li>

      </ul>
      <span>3. Die Reiff Verlag GmbH & Co. KG behält sich trotz nicht bestehender gesetzlicher Verpflichtung das Recht vor, die Veröffentlichung von Anzeigen und sonstigen Inhalten auf Website kleinanzeigen.bo.de aus Sicherheitsgründen zu verzögern.</span>

      <span class="font-semibold text-base">§ 3 Missbrauch von kleinanzeigen.bo.de; Löschen von Anzeigen, Sperrung und sonstige Maßnahmen</span>
      <span> Die Reiff Verlag GmbH & Co. KG kann seinen Service einschränken oder beenden, auf der Website kleinanzeigen.bo.de eingestellte Anzeigen oder sonstige Inhalte löschen, Nutzer verwarnen und vorläufig oder dauerhaft sperren, wenn konkrete Anhaltspunkte dafür vorliegen, dass ein Nutzer gesetzliche Vorschriften, Rechte Dritter oder diese Nutzungsbedingungen verletzt.</span>

      <span class="font-semibold text-base">§ 4 Gebühren und Leistungen</span>
      <span>1. Die Nutzung der Website kleinanzeigen.bo.de ist grundsätzlich kostenlos. Für bestimmte Leistungen und Funktionalitäten, sowie das Veröffentlichen von Anzeigen in bestimmten Kategorien, behalten wir uns jedoch das Recht vor, Gebühren zu verlangen. Wir werden Sie jedoch vor der Inanspruchnahme einer entgeltlichen Leistung darauf hinweisen, so dass Sie bei der Nutzung dieser Leistung ausdrücklich dem Entgelt zustimmen.</span>
      <span>2. Alle Gebühren werden in Euro angezeigt und können von uns jederzeit geändert werden. Über mögliche Änderungen der Gebühren werden wir Sie benachrichtigen und Sie stimmen den neuen Gebühren zu, wenn Sie nach der Änderung erneut die entgeltlichen Leistungen in Anspruch nehmen.</span>
      <span>3. Wir behalten uns auch das Recht vor, unsere Gebühren nur zeitweilig für bestimmte Promotions, Einführung neuer Funktionalitäten oder anderer Marketingaktionen zu ändern. Diese Gebührenänderungen treten mit Ihrer Bekanntmachung in Kraft, es sei denn, dass dies ausdrücklich anders in der Bekanntmachung geregelt ist.</span>
      <span>4. Die einzelnen Gebühren sind sofort zur Zahlung fällig und können über die auf der Website angebotenen Zahlungsmethoden beglichen werden. Schlägt der Forderungseinzug fehl, so haben Sie uns die dafür anfallenden Mehrkosten zu erstatten, soweit Sie das Fehlschlagen zu vertreten haben. Wenn Sie entgeltliche Leistungen in Anspruch nehmen und die Gebühren nicht zahlen, können wir Sie sperren, weiter unsere Leistungen in Anspruch zu nehmen.</span>

      <span class="font-semibold text-base">§ 5 Inhalte und Nutzungsrechte</span>
      <span>1. Die Webseite kleinanzeigen.bo.de enthält sowohl Inhalte von uns als auch Anzeigen und andere Informationen von anderen Nutzern. Sie willigen ein, Inhalte nicht zu kopieren, zu ändern oder zu verbreiten. Wenn Sie glauben, dass Ihre Rechte verletzt wurden, informieren Sie uns bitte. Wir behalten uns vor, Inhalte von unserer Website zu entfernen, wenn wir Anhaltspunkte für die Verletzung unserer Nutzungsbedingungen, unserer Grundsätze oder die Verletzung von Rechten Dritter haben.</span>
      <span>2. Für die Inhalte, die Sie mit Ihrer Anzeige auf kleinanzeigen.bo.de einstellen (Bilder und Artikelbeschreibungen), räumen Sie der Reiff Verlag GmbH & Co. KG und den mit der Reiff Verlag GmbH & Co. KG verbundenen Unternehmen das nicht ausschließliche, weltweite, unbefristete, unbegrenzte, unwiderrufliche, übertragbare und unterlizenzierbare, unentgeltliche Nutzungsrecht in allen bekannten und noch nicht bekannten Nutzungsarten ein bezüglich aller Urheberrechte, Datenbankrechte, gewerblicher, immaterieller oder sonstiger Schutzrechte.</span>

      <span class="font-semibold text-base">§ 6 Schutz geistigen Eigentums</span>
      <span>Berechtigte, insbesondere Inhaber von Urheberrechten, Warenzeichenrechten oder anderen Schutzrechten, können Angebote, die ihre Rechte verletzen, melden und deren Entfernung beantragen. Wenn ein Rechtsvertreter der berechtigten Person uns dies auf korrekte Art und Weise meldet, werden die Angebote, die die Schutz- oder Urheberrechte verletzen, von uns entfernt.</span>

      <span class="font-semibold text-base">Melden einer Rechtsverletzung</span>
      <span>Für die Teilnahme am Programm zur Mitteilung über eine mögliche Rechtsverletzung genügt es, das Formular zur Mitteilung über eine mögliche Rechtsverletzung auszufüllen, zu unterschreiben und per Fax an uns zu senden. Sie können dieses Formular verwenden, um Anzeigen zu melden, die Ihre Schutz- oder Urheberrechte verletzen. Die Angaben im Formular zur Mitteilung über eine mögliche Rechtsverletzung werden verwendet, um sicherzustellen, dass die Partei, die diese Anzeige(n) meldet, entweder der Rechteinhaber selbst oder ein offizieller bevollmächtigter Vertreter ist. Diese Angaben müssen auch Auskunft darüber geben, welche Anzeige entfernt werden soll.</span>
      <span>Hinweis: Dieses Formular darf nur vom rechtmäßigen Inhaber der Schutz- und Urheberrechte verwendet werden. Mit der Möglichkeit zur „Mitteilung über eine mögliche Rechtsverletzung“ soll dafür gesorgt werden, dass die Produktangebote bei kleinanzeigen.bo.de keine Urheberrechte, Warenzeichenrechte oder anderen Schutzrechte Dritter verletzen. Für die Kontaktaufnahme bei anderen Fragen steht Besuchern und Inserenten das Kontaktformular zur Verfügung.</span>

      <span class="font-semibold text-base">§ 7 Haftungsbeschränkung</span>
      <span>1. Die Anzeigen auf kleinanzeigen.bo.de stammen von Nutzern. Die Reiff Verlag GmbH & Co. KG übernimmt keine Garantie oder Gewährleistung für die Richtigkeit der Anzeigen oder der Nutzerkommunikation sowie für die Qualität, Sicherheit oder Legalität der angebotenen Waren oder Dienstleistungen.</span>
      <span>2. Gegenüber Unternehmern haftet die Reiff Verlag GmbH & Co. KG für Schäden, außer im Fall der Verletzung solcher Pflichten aus dem Schuldverhältnis, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht, auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf und deren Verletzung die Erreichung des Vertragszwecks gefährdet (wesentliche Vertragspflichten), nur, wenn und soweit die Reiff Verlag GmbH & Co. KG, ihren gesetzlichen Vertretern, leitenden Angestellten oder sonstigen Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fällt. Im Fall der Verletzung wesentlicher Vertragspflichten haftet Marktplaats BV für jedes schuldhafte Verhalten ihrer gesetzlichen Vertreter, leitenden Angestellten oder sonstigen Erfüllungsgehilfen.</span>
      <span>3. Gegenüber Verbrauchern haftet die Reiff Verlag GmbH & Co. KG nur für Vorsatz und grobe Fahrlässigkeit. Im Falle der Verletzung wesentlicher Vertragspflichten, des Schuldnerverzugs oder der von der Reiff Verlag GmbH & Co. KG zu vertretenden Unmöglichkeit der Leistungserbringung haftet die Reiff Verlag GmbH & Co. KG jedoch für jedes schuldhafte Verhalten ihrer Mitarbeiter und Erfüllungsgehilfen.</span>
      <span>4. Außer bei Vorsatz oder grober Fahrlässigkeit gesetzlicher Vertreter, leitender Angestellter oder sonstiger Erfüllungsgehilfen der Reiff Verlag GmbH & Co. KG, ist die Haftung der Höhe nach auf die bei Vertragsschluss typischerweise vorhersehbaren Schäden begrenzt.</span>
      <span>5. Eine Haftung für den Ersatz mittelbarer Schäden, insbesondere für entgangenen Gewinn, besteht nur bei Vorsatz oder grober Fahrlässigkeit gesetzlicher Vertreter, leitender Angestellte oder sonstiger Erfüllungsgehilfen der Reiff Verlag GmbH & Co. KG.</span>
      <span>6. Die vorgenannten Haftungsausschlüsse und Beschränkungen gegenüber Unternehmern oder Verbrauchern gelten nicht im Fall der Übernahme ausdrücklicher Garantien durch die Reiff Verlag GmbH & Co. KG und für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie im Fall zwingender gesetzlicher Regelungen.</span>

      <span class="font-semibold text-base">§ 8 Datenschutz</span>
      <span>Durch die Nutzung der Website kleinanzeigen.bo.de stimmen Sie der Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten durch die Reiff Verlag GmbH & Co. KG als verantwortlicher speichernder Stelle auf Servern zu, die sich in Deutschland befinden . Näheres hierzu finden Sie in unserer Datenschutzerklärung.</span>
      <span></span>

      <span class="font-semibold text-base">§ 9 Schlußbestimmungen</span>
      <span>1. Diese Bedingungen und die anderen auf der Website kleinanzeigen.bo.de hinterlegten Bestimmungen und Grundsätze stellen die gesamte Vereinbarung zwischen der Reiff Verlag GmbH & Co. KG und den Nutzern dar und ersetzen alle vorherigen Vereinbarungen.</span>
      <span>2. Diese Vereinbarung unterliegt den Gesetzen der Bundesrepublik Deutschland und sämtliche Streitigkeiten in Zusammenhang mit diesen Nutzungsbedingungen sollen von den Gerichten in Offenburg, Deutschland, entschieden werden. Von diesen Regelungen unberührt bleiben die Rechte, die Ihnen möglicherweise als Verbraucher kraft Gesetz zustehen und die unter Umständen die Anwendbarkeit einer anderen Rechtsordnung (zum Beispiel der Ihres Wohnsitzes) vorschreiben. Falls wir bestimmte Vorschriften nicht durchsetzen, verzichten wir damit nicht auf das Recht, dies zu einem späteren Zeitpunkt zu tun. Falls eine dieser Bedingungen unwirksam sein sollte, behalten die anderen Bedingungen weiterhin ihre Wirksamkeit.</span>
      <span>3. Die Reiff Verlag GmbH & Co. KG ist berechtigt, mit einer Ankündigungsfrist von vier Wochen seine Rechte und Pflichten aus diesem Vertragsverhältnis ganz oder teilweise auf einen Dritten zu übertragen.</span>
      <span>4. Die Reiff Verlag GmbH & Co. KG behält sich vor, diese Nutzungsbedingungen jederzeit und ohne Nennung von Gründen zu ändern. Die geänderten Bedingungen werden dem Nutzer vor dem Einstellen einer neuen Anzeige bekannt gegeben. Die geänderten Nutzungsbedingungen werden wirksam, sobald der Nutzer diesen bei der Aufgabe einer neuen Anzeige zustimmt.</span>
    </div>
  </div>
</kaz-default-frame>
