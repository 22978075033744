<div class="relative flex">
  <input class="w-full leading-4 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px]"
         [class]="inputType !== 'number'? 'px-4' : 'pl-4 pr-20'"
         [type]="inputType"
         [(ngModel)]="value"
         (input)="onInputChange()"
         [title]="title"
         [placeholder]="placeholder"
         [style.width]="width"
         [style.height]="height"
         [attr.maxlength]="maxLength"
         (keydown)="onKeyDown($event)"
  >
  <div *ngIf="showEye" (click)="changeInputType()" [title]="showPassword ? 'Ausblenden' : 'Anzeigen'" class="absolute cursor-pointer right-4 top-1/2 transform -translate-y-1/2">
    <fa-icon [icon]="showPassword ? 'eye-slash' : 'eye'"></fa-icon>
  </div>
</div>


