import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'kaz-cancel-ad',
  templateUrl: './cancel-ad.component.html',
  styleUrls: ['./cancel-ad.component.scss']
})
export class CancelAdComponent {
  constructor(private _router: Router,) {
  }

  ngOnInit() {
    setTimeout(() => {
      this._router.navigate(['profile']).then();
    }, 4000);
  }
}
