<div *ngIf="showHint && category !== 'NONE'" class="fixed top-0 left-0 w-full h-full bg-black/70 z-10">
  <div
    class="fixed flex flex-col gap-4 bottom-0 left-0 w-full bg-[#F0811A] p-[18px] text-white rounded-t-[20px] shadow-md transition-transform md:left-1/2 md:transform md:-translate-x-1/2 md:max-w-[1080px] md:rounded-[20px] md:bottom-8">
    <span class="font-semibold text-lg">
      Folgende Punkte machen Ihre Anzeige noch interessanter:
    </span>

    <div *ngIf="category === 'CAR'" class="grid grid-cols-[auto_1fr] gap-4 text-white">
      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Fahrzeugtyp</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Kraftstoffart</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Leistung</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Zustand</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Ausstattungen wie z.B. Sitzheizung, Multifunktionslenkrad oder Anhängerkupplung</span>
    </div>

    <div *ngIf="category === 'PROPERTY'" class="grid grid-cols-[auto_1fr] gap-4 text-white">
      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Energieausweis</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Wohnfläche/Grundstücksfläche</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Standort</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Miete, Nebenkosten und Kaution</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Verfügbarkeit ab</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Ausstattungen wie z.B. Balkon oder Garage</span>
    </div>

    <div *ngIf="category === 'JOB'" class="grid grid-cols-[auto_1fr] gap-4 text-white">
      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Mögliche Arbeitszeiten</span>

      <fa-icon [icon]="['fas','circle-dot']"></fa-icon>
      <span>Berufserfahrung</span>
    </div>


    <kaz-button class="md:hidden" text="Info schließen" buttonType="cookies" (onClick)="closeHintInfo()"></kaz-button>
    <kaz-button class="hidden md:block  md:self-center" text="Info schließen" width="343px" buttonType="cookies"
                (onClick)="closeHintInfo()"></kaz-button>
  </div>
</div>
