<kaz-default-frame>
  <div class="flex flex-col gap-8 text-sm text-[#222222] pb-6">
    <span class="font-semibold text-xl">FAQ</span>
    <div class="border border-[#D2D2D2] rounded-[8px] shadow overflow-hidden">
      <div *ngFor="let item of items">
        <kaz-collapse [item]="item"></kaz-collapse>
      </div>
    </div>
  </div>
</kaz-default-frame>
