<div class="flex flex-col w-[328px] h-auto border border-[#F1F1F1] p-4 rounded-[20px] text-kaz-black-text gap-3">
  <span class="text-base font-semibold">Schnellsuche</span>
  <!-- Divider -->
  <div class="h-[1px] bg-[#EFEFEF]"></div>
  <div *ngFor="let cat of getCategoryList()">
    <div class="grid grid-cols-[1fr_auto]">
      <a routerLink="/entry/search" [queryParams]="{ search: cat }"
         class="text-sm font-semibold hover:text-kaz-orange">{{ cat }}</a>
      <fa-icon *ngIf="isExpandable(cat)" class="px-5 text-kaz-blue md:hover:text-kaz-orange md:cursor-pointer"
               [icon]="showList(cat) ? 'chevron-up' : 'chevron-down'" [title]="showList(cat) ? 'weniger' : 'mehr'"
               (click)="changeActiveState(cat)"></fa-icon>
    </div>
    <div *ngIf="showList(cat)" class="flex flex-col indent-4">
      <div *ngFor="let sub of getFilteredSubCategory(cat)">
        <div class="grid grid-cols-[1fr_auto]">
          <a routerLink="/entry/search" [queryParams]="{ search: sub.name }"
             class="text-sm font-normal hover:text-kaz-orange">{{ sub.name }}</a>
          <fa-icon *ngIf="isExpandable(sub.name)" class="px-5 text-kaz-blue md:hover:text-kaz-orange md:cursor-pointer"
                   [icon]="showLocality ? 'chevron-up' : 'chevron-down'"
                   [title]="showLocality ? 'weniger' : 'mehr'"
                   (click)="changeActiveState(sub.name)"></fa-icon>
        </div>
        <div *ngIf="sub.name === 'Vermietungen' && showLocality" class="flex flex-col indent-8">
          <div *ngFor="let locality of localityList">
            <a routerLink="/entry/search" [queryParams]="{ search: locality }"
               class="text-sm font-normal hover:text-kaz-orange">{{ locality }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
