import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'kaz-no-frame',
  templateUrl: './no-frame.component.html',
  styleUrls: ['./no-frame.component.scss']
})
export class NoFrameComponent implements OnInit{
  showLogin: boolean = false;

  showLoginBtn: boolean = false;
  showSignUpBtn: boolean = false;

  constructor(private _router: Router) {
  }

  ngOnInit() {
    this.isLogin();
    this.isSignUp();
    if (this._router.url.includes('signup')) {
      this.showLogin = true;
    }
  }

  isLogin(){
    this.showLoginBtn = !this._router.url.includes('login');

  }

  isSignUp(){
    this.showSignUpBtn = !this._router.url.includes('signup');
  }

  activateAccountMenu(){
    return this._router.url.includes('login') || this._router.url.includes('signup') || this._router.url.includes('profile');
  }

  activateCreateAdMenu(){
    return this._router.url.includes('entry/create');
  }

  showFilter() {
    this._router.navigate(['/entry/search']).then();
  }
}
