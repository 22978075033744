<kaz-no-frame>
  <div class="flex flex-col md:max-w-[488px] md:m-auto">
    <span class="flex text-xl text[#161D26] leading-7 font-semibold mb-10 md:mb-16">Konto erstellen</span>
    <!-- Input-Mobile -->
    <div class="flex flex-col gap-4 mb-2 md:hidden">
      <div class="flex flex-row gap-4">
        <kaz-input class="grow" [(value)]="firstname" placeholder="Vorname *"></kaz-input>
        <kaz-input class="grow" [(value)]="lastname" placeholder="Nachname *"></kaz-input>
      </div>
      <kaz-input [(value)]="phone" placeholder="Telefon"></kaz-input>
      <kaz-input [(value)]="mail" placeholder="E-Mail *"></kaz-input>
      <kaz-input [(value)]="password" inputType="password" placeholder="Passwort *" [showEye]="true"></kaz-input>
      <kaz-input [(value)]="repeatPassword" inputType="password" placeholder="Passwort wiederholen *" [showEye]="true"></kaz-input>
      <span class="flex text-[#161D26] text-[12px] font-medium mx-4 mb-12 md:hidden">
        <ng-container *ngIf="!uppercaseRegex.test(password)">{{ 'Mindestens 1 Großbuchstabe, ' }}</ng-container>
        <ng-container *ngIf="!digitRegex.test(password)">{{ 'eine Zahl, ' }}</ng-container>
        <ng-container *ngIf="!specialCharRegex.test(password)">{{ 'ein Sonderzeichen, ' }}</ng-container>
        <ng-container *ngIf="!minLengthRegex.test(password)">{{ 'mind. 8 Zeichen.' }}</ng-container>
      </span>
    </div>
    <!-- Input-Desktop -->
    <div class="hidden md:grid md:grid-cols-[auto_1fr] md:gap-8 md:items-center md:mb-12">
      <span>Vorname *</span>
      <kaz-input [(value)]="firstname" [capitalizeFirstLetter]="true"
                 placeholder=""></kaz-input>
      <span>Nachname *</span>
      <kaz-input [(value)]="lastname" [capitalizeFirstLetter]="true"
                 placeholder=""></kaz-input>
      <span>Telefon</span>
      <kaz-input [(value)]="phone"
                 placeholder=""></kaz-input>
      <span>E-Mail *</span>
      <kaz-input [(value)]="mail"
                 placeholder=""></kaz-input>
      <span>Passwort *</span>
      <div class="flex flex-col gap-y-1.5">
        <kaz-input [(value)]="password" inputType="password" [showEye]="true"
                   placeholder=""></kaz-input>
        <span class="flex text-[#161D26] text-[12px] font-medium mx-4">
          <ng-container *ngIf="!uppercaseRegex.test(password)">{{ 'Mindestens 1 Großbuchstabe, ' }}</ng-container>
          <ng-container *ngIf="!digitRegex.test(password)">{{ 'eine Zahl, ' }}</ng-container>
          <ng-container *ngIf="!specialCharRegex.test(password)">{{ 'ein Sonderzeichen, ' }}</ng-container>
          <ng-container *ngIf="!minLengthRegex.test(password)">{{ 'mind. 8 Zeichen.' }}</ng-container>
        </span>
      </div>

      <span>Passwort wiederholen *</span>
      <kaz-input [(value)]="repeatPassword" inputType="password" [showEye]="true"
                 placeholder=""></kaz-input>
    </div>
    <span class="text-kaz-black-text text-sm mb-4">* Pflichtangaben</span>
    <div class="flex justify-center items-center mb-4">
      <span class="text-[#A7A7A7] text-[8px] font-medium leading-4 mx-4 md:w-[343px] md:self-center md:px-4 md:mx-0">
        Es gelten die <a class="underline text-[#224261]" routerLink="/agb">kleinanzeigen.bo.de AGB</a>.
        Informationen zur Verarbeitung Ihrer Daten finden Sie in unserer <a class="underline text-[#224261]" routerLink="/privacy">Datenschutzerklärung</a>.
      </span>
    </div>
    <kaz-button class="md:w-[343px] md:self-center" [isLoading]="isLoading" [disabled]="isDisabled()" (onClick)="signup()"
                text="Konto erstellen"></kaz-button>
  </div>
</kaz-no-frame>
