import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/auth.service";

@Component({
  selector: 'kaz-slide-menu',
  templateUrl: './kaz-slide-menu.component.html',
  styleUrls: ['./kaz-slide-menu.component.scss']
})
export class KazSlideMenuComponent implements OnInit{

  constructor(private _auth: AuthenticationService) {}

  ngOnInit() {

  }

  isLoggedIn(){
    return this._auth.isAuthenticated();
  }
}
