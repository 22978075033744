
//LastSeenAds
import {Ad} from "../interfaces/ad";

export function setLastSeen(id: string) {
  const response = sessionStorage.getItem('lastSeen');
  const lastSeen: string[] = response ? JSON.parse(response) : [];

  if (!lastSeen.includes(id))
    lastSeen.unshift(id);

  if (lastSeen.length > 10) {
    lastSeen.pop();
  }

  sessionStorage.setItem('lastSeen', JSON.stringify(lastSeen));
}

export function getLastSeen() {
  const response = sessionStorage.getItem('lastSeen');
  if (response)
    return JSON.parse(response);
  else
    return [];
}

export function clearLastSeen() {
  sessionStorage.removeItem('lastSeen');
}

//OldAd
export function setPreviousAd(ad: Ad) {
  sessionStorage.setItem('previousAd', JSON.stringify(ad));
}

export function getPreviousAd(){
  const response = sessionStorage.getItem('previousAd');
  if (response)
    return JSON.parse(response);
  else
    return null;
}

export function clearPreviousAd() {
  sessionStorage.removeItem('previousAd');
}
