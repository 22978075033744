<kaz-default-frame>
  <div class="flex flex-col">
    <div class="flex flex-col md:hidden">
      <kaz-search></kaz-search>
      <!-- Divider -->
      <div class="my-4 border-t border-[#EFEFEF]"></div>
      <kaz-slide-menu></kaz-slide-menu>
      <!-- Divider -->
      <div class="mt-0 mb-3 border-t border-[#EFEFEF]"></div>
    </div>
    <!-- Zuletzt angesehen -->
    <div *ngIf="lastSeen.length > 0 && hasValidatedAd()" class="flex flex-col gap-2 md:bg-[#FAFAFA] md:p-4 md:mb-7 md:rounded-[2px]">
      <span class="font-semibold text-xl">Zuletzt angesehen</span>
        <div class="flex flex-row gap-2 pb-5 overflow-x-auto snap-x md:pb-0">
          <div *ngFor="let last of lastSeen" class="flex flex-row">
            <kaz-card-last-seen *ngIf="last.validated && !last.reserved" class="snap-center" [ad]="last"></kaz-card-last-seen>
          </div>
        </div>
    </div>
    <!-- Divider -->
    <div *ngIf="lastSeen.length > 0" class="mt-0 mb-5 border-t border-[#EFEFEF] md:hidden"></div>
    <!-- Für Sie empfohlen -->
    <span class="font-semibold text-xl mb-2 md:mb-4">Für Sie empfohlen</span>
    <div class="flex flex-col gap-2 md:grid md:grid-cols-4">
      <div *ngFor="let recommend of recommended">
        <kaz-card-recommended [ad]="recommend"></kaz-card-recommended>
      </div>
    </div>
    <kaz-button *ngIf="recommended.length > 0 && nextToken !== '@end'" [isLoading]="loadingAds" (onClick)="loadMoreRecommends()" class="mt-4 md:w-[343px] md:self-center mb-12" buttonType="bordered" text="Mehr laden"></kaz-button>
  </div>
</kaz-default-frame>
