 <div class="relative">
  <button (click)="onClick.emit()"
          [title]="title"
          [style.width]="width"
          [style.height]="height"
          [disabled]="disabled"
          [class.deactivated]="disabled || isLoading"
          [attr.type]="fileButton ? 'file' : undefined"
          class="buttontype-{{buttonType}}">
    {{ text }}
  </button>
   <fa-icon style="position: absolute; left: 4px; top: 0; font-size: 32px; bottom: 0;" *ngIf="icon !== null && !isLoading" [icon]="icon"></fa-icon>
   <fa-icon style="position: absolute; left: 4px; top: 0; font-size: 32px; bottom: 0;" *ngIf="isLoading"                   [icon]="'loader'" animation="spin"></fa-icon>

  <img *ngIf="image !== ''" class="absolute h-6 w-6 top-3 left-5 bottom-3" [src]="image" alt="Img">
</div>
