<div class="relative flex flex-col px-4 py-2 md:p-0 md:bg-[#FEFEFE] md:mb-12">
  <!-- Top-Header -->
  <div
    class="flex grow justify-center mb-8 md:h-[100px] md:my-0 md:mx-[60px] md:flex-row md:justify-start md:items-center">
    <!-- Main-Image -->
    <img ngSrc="assets/images/logo.png" routerLink="/main" [class]="showLogin ?'h-8 md:h-14':'h-14'"
         class="h-14 w-auto md:mr-7 md:cursor-pointer" height="116" width="459" alt="logo">
    <div class="hidden md:flex md:w-full md:max-w-[668px]">
      <kaz-search class="md:grow"></kaz-search>
    </div>
    <!-- Filter - Schnellsuche -->
    <div (click)="showFilter()"
         class="hidden h-10 ml-4 text-sm text-[#D2D2D2] font-medium rounded-full cursor-pointer md:flex md:flex-row md:justify-center md:items-center md:hover:text-kaz-orange">
      <fa-icon icon="filter" size="sm"></fa-icon>
      <span>Filtern</span>
    </div>
    <!-- Sub-Image -->
    <div class="hidden md:grow md:flex md:justify-end">
      <img src="assets/images/newspaper_logo.png"
           class="h-9 w-auto" alt="logo">
    </div>
  </div>
  <!-- Bottom-Header -->
  <div class="hidden h-16 w-full bg-[#FAFAFA] px-[60px] md:flex md:flex-row md:mb-14">
    <!-- create-Ad -->
    <div routerLink="/entry/create"
         class="flex flex-row gap-2 h-full w-[187px] text-sm font-medium cursor-pointer items-center justify-center"
         [class]="activateCreateAdMenu()? 'text-[#222222] border-b-[2px] border-[#222222]':'text-[#A8A8A8] border-b border-[#D2D2D2]'">
      <fa-icon icon="tag" size="lg"></fa-icon>
      <span>Anzeige aufgeben</span>
    </div>
    <!-- Account -->
    <div routerLink="/profile"
         class="flex flex-row gap-2 h-full w-[187px] text-sm font-medium cursor-pointer items-center justify-center"
         [class]="activateAccountMenu()? 'text-[#222222] border-b-[2px] border-[#222222]':'text-[#A8A8A8] border-b border-[#D2D2D2]'">
      <fa-icon icon="user" size="lg"></fa-icon>
      <span>Konto</span>
    </div>
    <!-- Login or Create -->
    <div class="flex flex-row gap-4 grow justify-end items-center text-xs text-[#161D26] font-semibold">
      <div *ngIf="showSignUpBtn"
           class="flex justify-center items-center h-[33px] w-[186px] text-[#224361] border border-[#224361] rounded-full cursor-pointer"
           routerLink="/signup">
        <span>Konto erstellen</span>
      </div>
      <span *ngIf="showSignUpBtn && showLoginBtn" class="text-sm">oder</span>
      <div *ngIf="showLoginBtn"
           class="flex justify-center items-center h-[33px] w-[186px] text-white bg-[#F0811A] rounded-full cursor-pointer"
           routerLink="/login">
        <span>Anmelden</span>
      </div>
    </div>
  </div>

  <a *ngIf="showLogin" routerLink="/login"
     class="absolute right-4 top-5 text-[#224261] font-normal text-xs underline md:hidden">Anmelden</a>
  <div class="md:m-auto md:max-w-[1080px] md:w-full">
    <ng-content>
    </ng-content>
  </div>
</div>
<!-- Footer -->
<div
  class="fixed bottom-0 left-0 flex gap-2 border-t border-[#D2D2D2] w-full text-[10px] text-[#999999] p-2 bg-[#FAFAFA] justify-center md:gap-8 md:text-[8px] md:border-none">
  <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/imprint">Impressum</span>
  <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/privacy">Datenschutz</span>
  <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/conditions">Nutzungsbedingungen</span>
  <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/fundamentals">Grundsätze</span>
  <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/agb">AGB</span>
  <span class="hidden md:flex md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/faq">FAQ</span>
</div>
