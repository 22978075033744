import {Component, OnInit} from '@angular/core';
import {getDefaultInfoStatus, setDefaultInfoStatus} from "../../utils/localStorage";
import {AuthenticationService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'kaz-default-info',
  templateUrl: './kaz-default-info.component.html',
  styleUrls: ['./kaz-default-info.component.scss']
})
export class KazDefaultInfoComponent implements OnInit {
  showDefaultInfo: boolean = false;

  constructor(private _auth: AuthenticationService,
              private _router: Router) {
  }

  ngOnInit() {
    this.show();
  }

  show() {
    if (!this._auth.isAuthenticated() && this._router.url === '/main') {
      this.showDefaultInfo = getDefaultInfoStatus();
    } else {
      this.showDefaultInfo = false;
    }
  }

  closeDefaultInfo() {
    //setDefaultInfoStatus(true); //TODO: soll nach zwei wochen wieder eingefügt werden
    this.showDefaultInfo = false;
  }
}
