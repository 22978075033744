import {DatePipe} from '@angular/common';

export class DateUtils {

  private static dp = new DatePipe('en-US');

  static addDays(d: Date, delta: number): Date {
    let r = new Date(d);
    r.setDate(r.getDate() + delta);
    return r;
  }

  static addHours(d: Date, delta: number): Date {
    return new Date(d.getTime() + 3600000 * delta);
  }

  static now(): Date {
    return new Date(Date.now());
  }

  static tomorrow(): Date {
    return new Date(DateUtils.today().getTime() + 24 * 60 * 60 * 1000);
  }

  static yesterday(): Date {
    return new Date(DateUtils.today().getTime() - 24 * 60 * 60 * 1000);
  }

  static fromUnix(sec: number): Date {
    return new Date(sec * 1000);
  }

  static today(): Date {
    return this.startOfDay(this.now());
  }

  static format(d: Date, fmt: string): string {
    return DateUtils.dp.transform(d, fmt) as string;
  }

  static formatIsoDate(d: Date): string {
    return d.getFullYear().toString() + "-" + (d.getMonth()+1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0');
  }

  static formatIsoDateTime(d: Date): string {
    return d.getFullYear().toString() + "-" +
      (d.getMonth()+1).toString().padStart(2, '0') + "-" +
      d.getDate().toString().padStart(2, '0') + " " +
      d.getHours().toString().padStart(2, '0') + ":" +
      d.getMinutes().toString().padStart(2, '0') + ":" +
      d.getSeconds().toString().padStart(2, '0');
  }

  static formatLocalIsoDateTime(d: Date) {
    const tzo = -d.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = function(num: number) { return (num < 10 ? '0' : '') + num; };

    return d.getFullYear() +
      '-' + pad(d.getMonth() + 1) +
      '-' + pad(d.getDate()) +
      'T' + pad(d.getHours()) +
      ':' + pad(d.getMinutes()) +
      ':' + pad(d.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  static formatGermanDate(d: Date): string {
    return d.getDate().toString().padStart(2, '0') + "." +  (d.getMonth()+1).toString().padStart(2, '0') + "." + d.getFullYear().toString();
  }

  static formatRFC3339(d: Date|string): string {
    return DateUtils.parseRFC3339(d).toISOString();
  }

  static startOfDay(v: Date) {
    let r = new Date(v);
    r.setHours(0, 0, 0, 0);
    return r;
  }

  static endOfDay(v: Date) {
    let r = new Date(v);
    r.setHours(23, 59, 59, 999);
    return r;
  }

  static startOfWeek(v: Date) {
    v = new Date(v);
    v.setHours(0, 0, 0, 0);
    const day = v.getDay();
    const diff = v.getDate() - day + (day == 0 ? -6:1);
    return new Date(v.setDate(diff));
  }

  static endOfWeek(v: Date) {
    v = new Date(v);
    v.setHours(0, 0, 0, 0);
    const day = v.getDay();
    const diff = v.getDate() - day + (day == 0 ? -6:1) + 6;
    v.setHours(23, 59, 59, 999);
    return new Date(v.setDate(diff));
  }

  static getDayDifference(a: Date, b: Date) {
    return Math.round((b.getTime() - a.getTime()) / (1000 * 3600 * 24));
  }

  static getSecondDifference(a: Date, b: Date) {
    return Math.round((b.getTime() - a.getTime()) / 1000);
  }

  static getUnix(v: Date):number {
    return Math.floor(v.getTime()/1000);
  }

  static isSameDay(d: Date, n: Date): boolean {
    return (d.getDate() === n.getDate()) && (d.getMonth() === n.getMonth()) && (d.getFullYear() === n.getFullYear());
  }

  static mergeDateWithTime(date: Date, time: Date): Date {
    const d = new Date(date);
    d.setHours(time.getHours());
    d.setMinutes(0);
    d.setSeconds(0);
    return d;
  }

  static formatUTCIsoDateOnly(v: Date) {
    return `${v.getFullYear().toString()}-${(v.getMonth()+1).toString().padStart(2, '0')}-${v.getDate().toString().padStart(2, '0')}T00:00:00Z`;
  }

  static parseRFC3339(v: string|Date): Date {
    return new Date(v);
  }

  static fparseRFC3339(v: string|Date, fmt: string): string {
    return this.format(this.parseRFC3339(v), fmt);
  }

  static formatSeconds(value: number, forceSign:boolean = true, secSuffix:string=''): string {

    if (value === 0) {
      return forceSign ? '+0' : '0';
    }

    if (value < 0) {
      return '-' + this.formatSeconds(-value, forceSign);
    }

    value = Math.floor(value);

    let ss = value % 60;
    value -= ss;
    value /= 60;

    let mm = value % 60;
    value -= mm;
    value /= 60;

    let hh = value;

    if (hh === 0 && mm === 0) return `${ss.toString().padStart(2, '0')}${secSuffix}`;
    if (hh === 0)             return `${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`;
    return `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`;
  }

  static formatSecondsLong(value: number, withSec: boolean = true): string {

    if (value === 0) return '0 seconds';

    if (value < 0) {
      return '-' + this.formatSecondsLong(-value);
    }

    value = Math.floor(value);

    let ss = value % 60;
    value -= ss;
    value /= 60;

    let mm = value % 60;
    value -= mm;
    value /= 60;

    let hh = value;

    if (hh === 0 && mm === 0) return `${ss.toString()} seconds`;

    if (withSec) {
      if (hh === 0) return `${mm.toString()} minutes, ${ss.toString()} seconds`;
      return `${hh.toString()} hours, ${mm.toString()} minutes, ${ss.toString()} seconds`;
    } else {
      if (hh === 0) return `${mm.toString()} minutes`;
      return `${hh.toString()} hours, ${mm.toString()} minutes`;
    }
  }

  static formatSecondsSemiLong(value: number, withSec: boolean = true): string {

    if (value === 0) return '0sec';

    if (value < 0) return "- (" + this.formatSecondsSemiLong(-value) + ")";

    value = Math.floor(value);

    let ss = value % 60;
    value -= ss;
    value /= 60;

    let mm = value % 60;
    value -= mm;
    value /= 60;

    let hh = value;

    if (hh === 0 && mm === 0) return `${ss.toString()}sec`;

    if (withSec) {
      if (hh === 0) return `${mm.toString()}min, ${ss.toString()}sec`;
      return `${hh.toString()}h, ${mm.toString()}min, ${ss.toString()}sec`;
    } else {
      if (hh === 0) return `${mm.toString()}min`;
      return `${hh.toString()}h, ${mm.toString()}min`;
    }
  }

  static parseTimeStrToMilliseconds(v: string): number {
    let s = v.split(':');
    if (s.length === 1) {
      return (parseInt(s[0], 10) * 60 * 60 * 1000);
    } else
    if (s.length === 2) {
      return (parseInt(s[0], 10) * 60 * 60 * 1000) + (parseInt(s[1], 10) * 60 * 1000);
    } else
    if (s.length === 3) {
      return (parseInt(s[0], 10) * 60 * 60 * 1000) + (parseInt(s[1], 10) * 60 * 1000) + (parseInt(s[2], 10) * 1000);
    } else {
      return 0;
    }
  }

  static formatMilliseconds(v: number): string {
    const hh = Math.floor(v / (60 * 60 * 1000));
    v %= (60 * 60 * 1000);
    const mm = Math.floor(v / (60 * 1000));
    v %= (60 * 1000);
    const ss = Math.floor(v / (1000));
    v %= (1000);
    const tt = Math.floor(v);

    if (tt === 0 && ss === 0) {
      return `${`${hh}`.padStart(2, '0')}:${`${mm}`.padStart(2, '0')}`;
    } else if (tt === 0) {
      return `${`${hh}`.padStart(2, '0')}:${`${mm}`.padStart(2, '0')}:${`${ss}`.padStart(2, '0')}`;
    } else {
      return `${`${hh}`.padStart(2, '0')}:${`${mm}`.padStart(2, '0')}:${`${ss}`.padStart(2, '0')}.${tt}`;
    }
  }

  static getDateOnly(date: Date): Date {
    let copy = new Date(date);
    copy.setHours(0);
    copy.setMinutes(0);
    copy.setMilliseconds(0);
    return copy;
  }

  static getMonthOnly(date: Date): Date {
    let copy = new Date(date);
    copy.setDate(1);
    copy.setHours(0, 0, 0, 0);
    copy.setMinutes(0, 0, 0);
    copy.setMilliseconds(0);
    return copy;
  }

  static combineDateAndTime(datepart: Date, timepart: Date): Date {
    let copy = new Date(datepart);
    copy.setHours(timepart.getHours(), timepart.getMinutes(), timepart.getSeconds(), timepart.getMilliseconds());
    return copy;
  }

  static getWeekday(val: string|Date, shortrel: boolean) {

    const date = this.parseRFC3339(val);

    if (shortrel) {
      const offset = this.getDayDifference(DateUtils.today(), DateUtils.startOfDay(date));
      if (offset === -1) { return "Gestern"; }
      if (offset ===  0) { return "Heute";   }
      if (offset ===  1) { return "Morgen";  }
    }

    const arr = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    return arr[date.getDay()];
  }

  static dateEquals(v1: string|Date, v2: string|Date) {
    v1 = this.parseRFC3339(v1);
    v2 = this.parseRFC3339(v2);

    return (v1.getFullYear() === v2.getFullYear()) && (v1.getMonth() === v2.getMonth()) && (v1.getDate() === v2.getDate());
  }
}
