<div id="main_layout_scroll" class="relative h-screen" [class.overflow-scroll]="scrollable"
     [class.overflow-hidden]="!scrollable">

  <div class="flex flex-col px-4 py-1 md:p-0 md:bg-[#FEFEFE]">
    <!-- Top-Header -->
    <div
      class="flex flex-row max-h-8 mb-4 md:max-h-[100px] md:h-[100px] md:my-0 md:mx-[60px] md:flex-row md:justify-start md:items-center">
      <!--  Btns linke Seite  -->
      <div class="flex flex-row md:hidden">
        <div class="flex gap-1 w-auto h-8 items-center justify-center" *ngIf="backLink !== ''">
          <fa-icon icon="chevron-left" size="xs"></fa-icon>
          <span class="font-light text-sm">Zurück</span>
        </div>
      </div>
      <!--  Image  -->
      <div class="flex grow md:grow-0 md:mr-7" [class]="backLink !== '' ? 'justify-center' :'justify-start'"
           routerLink="/main">
        <img ngSrc="assets/images/logo.png" class="h-8 w-auto md:h-14 md:cursor-pointer" height="116" width="459"
             alt="logo">
      </div>
      <!-- Search-Desktop -->
      <div class="hidden md:flex md:w-full md:max-w-[668px]">
        <kaz-search class="md:grow"></kaz-search>
      </div>
      <!-- Filter - Schnellsuche -->
      <div (click)="showFilter()"
        class="hidden h-10 ml-4 text-sm text-[#D2D2D2] font-medium rounded-full cursor-pointer md:flex md:flex-row md:justify-center md:items-center md:hover:text-kaz-orange">
        <fa-icon icon="filter" size="sm"></fa-icon>
        <span>Filtern</span>
      </div>
      <!-- Sub-Image -->
      <div class="hidden md:grow md:flex md:justify-end">
        <img src="assets/images/newspaper_logo.png"
             class="h-9 w-auto" alt="logo">
      </div>
      <!--  Btns rechte Seite  -->
      <div class="flex flex-row md:hidden">
        <div *ngIf="!(showMenu && _auth.isAuthenticated() || isProfilePage())"
             class="flex w-8 h-8 items-center justify-center" routerLink="/profile">
          <fa-icon icon="user" size="sm"></fa-icon>
        </div>
        <div class="flex w-8 h-8 items-center justify-end" (click)="toggleMenu()">
          <fa-icon [ngClass]="{'menu-icon': showMenu}" [icon]="showMenu ? 'xmark' : 'bars'" size="sm"></fa-icon>
        </div>
      </div>
    </div>
    <!-- Bottom-Header -->
    <div class="hidden h-16 w-full bg-[#FAFAFA] px-[60px] md:flex md:flex-row md:mb-14">
      <!-- create-Ad -->
      <div routerLink="/entry/create"
           class="flex flex-row gap-2 h-full w-[187px] text-sm font-medium cursor-pointer items-center justify-center"
           [class]="activateCreateAdMenu()? 'text-[#222222] border-b-[2px] border-[#222222]':'text-[#A8A8A8] border-b border-[#D2D2D2]'">
        <fa-icon icon="tag" size="lg"></fa-icon>
        <span>Anzeige aufgeben</span>
      </div>
      <!-- Account -->
      <div routerLink="/profile"
           class="flex flex-row gap-2 h-full w-[187px] text-sm font-medium cursor-pointer items-center justify-center"
           [class]="activateAccountMenu()? 'text-[#222222] border-b-[2px] border-[#222222]':'text-[#A8A8A8] border-b border-[#D2D2D2]'">
        <fa-icon icon="user" size="lg"></fa-icon>
        <span>Konto</span>
      </div>
      <!-- Login or Create -->
      <div *ngIf="showAuthBtns"
           class="flex flex-row gap-4 grow justify-end items-center text-xs text-[#161D26] font-semibold">
        <div
          class="flex justify-center items-center h-[33px] w-[186px] text-[#224361] border border-[#224361] rounded-full cursor-pointer"
          routerLink="/signup">
          <span>Konto erstellen</span>
        </div>
        <span class="text-sm">oder</span>
        <div
          class="flex justify-center items-center h-[33px] w-[186px] text-white bg-[#F0811A] rounded-full cursor-pointer"
          routerLink="/login">
          <span>Anmelden</span>
        </div>
      </div>

      <!-- Mail & Logout -->
      <div *ngIf="!showAuthBtns"
           class="flex flex-row gap-11 grow justify-end items-center text-xs text-[#161D26] font-medium">
        <span class="cursor-pointer" routerLink="/profile">{{ mail }}</span>
        <span class="text-[#F0811A] font-semibold cursor-pointer" routerLink="/login" (click)="logout()">Abmelden</span>
      </div>
    </div>
    <kaz-default-info class="hidden absolute right-16 z-10 top-[180px] md:flex"></kaz-default-info>

    <div class="md:m-auto md:max-w-[1080px] md:w-full pb-10 md:pb-8">
      <ng-content>
      </ng-content>
    </div>
    <kaz-menu *ngIf="showMenu" (closeMenu)="closeMenu()"></kaz-menu>
  </div>
  <!-- Footer -->
  <div
    class="fixed bottom-0 left-0 flex gap-2 w-full text-[10px] text-[#999999] p-2 border-t border-[#D2D2D2] bg-[#FAFAFA] justify-center md:gap-8 md:text-[8px] md:border-none">
    <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/imprint">Impressum</span>
    <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/privacy">Datenschutz</span>
    <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/conditions">Nutzungsbedingungen</span>
    <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/fundamentals">Grundsätze</span>
    <span class="md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/agb">AGB</span>
    <span class="hidden md:flex">|</span>
    <span class="hidden md:flex md:hover:text-[#F0811A] md:hover:cursor-pointer" routerLink="/faq">FAQ</span>
  </div>

</div>
