<a *ngIf="ad !== null"
     class="relative flex flex-row bg-[#FEFEFE] rounded-[6px] border border-[#F1F1F1] overflow-hidden md:rounded-[15px]" routerLink="/entry/{{ad.id}}">
  <!-- Bild -->
  <div class="relative flex h-[85px] w-[140px] justify-center items-center overflow-hidden md:h-[180px] md:w-[300px] md:cursor-pointer">
    <!-- BackgroundImage -->
    <img class="absolute left-0 top-0 z-0 w-full h-full object-cover blur"
         [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
    <div class="absolute left-0 top-0 z-0 w-full h-full bg-black/50"></div>
    <!-- Image -->
    <img class="flex w-auto h-full z-0 object-cover"
         [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
  </div>
  <!-- lables -->
  <div class="relative flex flex-col p-4 w-full md:px-8 md:cursor-pointer">
    <span class="text-[#161D26] text-sm font-medium md:text-lg md:mb-2" [title]="ad.title">{{ ad.title }}</span>
    <span class="hidden md:flex text-[#999999] text-base font-normal">
      <span class="line-clamp-3 break-words hyphens-auto" lang="de">{{ ad.description }}</span>
    </span>
    <span *ngIf="ad.category.subName !== 'Verschenken'  && +ad.price > 0"
          class="absolute left-4 bottom-2.5 text-xs text-[#56BA90] font-medium md:text-xl md:font-semibold md:left-8 md:bottom-5">€ {{ ad.price | currency:'EUR':'':'1.0-0':'de-DE' }} {{ ad.priceType === 'VHB' ? 'VHB' : '' }}</span>
    <span
      class="absolute right-4 bottom-2.5 text-xs text-[#999999]/70 font-medium md:right-8 md:bottom-5">{{ ad.category.localityName !== '' ? ad.category.localityName : ad.category.subName }}</span>

    <div class="absolute bottom-2.5 right-5 h-5 flex flex-row gap-1 text-[#999999] text-xs font-medium items-center">
      <fa-icon class="hidden" icon="eye" size="sm"></fa-icon>
      <span class="hidden mr-2">600</span>
      <fa-icon class="hidden" icon="heart" size="sm"></fa-icon>
      <span class="hidden">300</span>
    </div>
  </div>
</a>
