<kaz-no-frame>
  <div *ngIf="user !== null" class="flex flex-col md:max-w-[1080px] md:m-auto mb-4 h-full items-center justify-center">
      <fa-icon class="text-[#F0811A] my-20" icon="envelope" size="10x"></fa-icon>
      <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
        Bestätigen Sie Ihre E-Mail.
      </span>

      <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
        Sie habe eine E-Mail von uns erhalten. Falls nicht, können Sie mit dem unteren Button erneut eine Verifizierungsmail anfordern.
      </span>

      <kaz-button class="mt-4" width="236px" [disabled]="mailRequested" (onClick)="sendVerifyMail()" buttonType="primary"
                text="E-Mail erneut senden"></kaz-button>

      <span *ngIf="mailRequested" class="mt-4 mx-8 text-center font-medium text-base text-[#F0811A]">
        E-Mail erneut angefordert.
      </span>
    </div>
</kaz-no-frame>
