import {Ad} from "../interfaces/ad";

export function getDummyPicture(ad: Ad) {
  if (ad !== null) {
    if (ad.category.subName === 'Bekanntschaften' || ad.category.subName === 'Tierschutz' || ad.category.subName === 'Entlaufen/Entflogen' || ad.category.subName === 'Zugelaufen/Zugeflogen') {
      switch (ad.category.subName) {
        case 'Bekanntschaften':
          return 'assets/images/Dummy_Bekanntschaften.png';
        case 'Tierschutz':
        case 'Entlaufen/Entflogen':
        case 'Zugelaufen/Zugeflogen':
          return 'assets/images/Dummy_Tiere.png';
        default:
          return 'assets/images/Dummy_Allgemein.png';
      }
    } else {
      switch (ad.category.name) {
        case 'Dies & Das':
          return 'assets/images/Dummy_Allgemein.png';
        case 'Immobilien':
          return 'assets/images/Dummy_Immo.png';
        case 'Job':
          return 'assets/images/Dummy_Job.png';
        case 'Auto':
        case 'Mobiles':
          return 'assets/images/Dummy_Auto.png';
        default:
          return 'assets/images/Dummy_Allgemein.png';
      }
    }
  }
  return 'assets/images/Dummy_Allgemein.png'
}
