<kaz-no-frame>
  <div class="flex flex-col md:max-w-[488px] md:m-auto">
    <span class="text-xl text-[#161D26] leading-7 font-semibold mb-3">Hilfe beim Anmelden</span>
    <span class="text-base text-[#161D26]  mb-14 font-medium">
      Aus Sicherheitsgründen möchten wir Ihre Identität bestätigen.
    </span>
    <div class="flex flex-row gap-6 text-[#224361]">
      <fa-icon class="ml-5 mt-8" size="2xl" icon="envelope"></fa-icon>
      <div class="flex flex-col text-[#161D26] mr-20">
        <span class="text-base font-medium mb-2" [class]="wasSend ? 'text-[#F0811A]' : 'text-[#161D26]'">{{wasSend ? 'E-Mail gesendet' : 'E-Mail'}}</span>
        <span class="text-xs mb-1">{{wasSend ? 'Wir haben Ihnen einen Sicherheitscode per E-Mail an' : 'Wir senden Ihnen einen Sicherheitscode per E-Mail an'}}</span>
        <kaz-input *ngIf="!wasSend" class="my-4" [(value)]="mail" placeholder="E-Mail"></kaz-input>
        <span *ngIf="wasSend" class="font-medium mb-2">{{mail}}</span>
        <span *ngIf="wasSend" class="text-xs ">gesendet.</span>

        <kaz-button [class]="wasSend ? 'hidden' : 'inline'" [disabled]="isDisabled()" (onClick)="sendResetMail()" text="E-Mail erhalten" buttonType="secondary"></kaz-button>
      </div>
    </div>
  </div>
</kaz-no-frame>
