<div *ngIf="ad !== null" [class]="ad.validated ? 'bg-[#FEFEFE] ':'bg-[#F7F7F7]'"
     class="relative flex flex-col rounded-[6px] border border-[#F1F1F1] overflow-hidden md:rounded-[15px]">
  <div class="flex flex-row">
    <!-- Image -->
    <a class="relative flex h-[85px] w-[140px] items-center justify-center overflow-hidden md:h-[100px] md:w-[220px] md:cursor-pointer rounded-br-[15px]" routerLink="/entry/{{ad.id}}">
      <!-- BackgroundImage -->
      <img class="absolute left-0 top-0 z-0 w-full h-full object-cover blur"
           [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
      <div class="absolute left-0 top-0 z-0 w-full h-full bg-black/50"></div>
      <img class="w-auto h-full z-0 object-cover md:rounded-br-[15px]" [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)"
           alt="Bild">
      <div *ngIf="ad.reserved"
           class="absolute flex top-0 left-0 w-full h-full bg-black/70 text-xs text-white font-semibold justify-center items-center md:text-base md:rounded-br-[15px]">
        <span>Reserviert</span>
      </div>
      <span *ngIf="ad.images.length > 0"
        class="absolute right-1 bottom-1 bg-[#183C5B] px-1.5 text-xs text-white font-semibold rounded-[2px] md:text-base md:px-3 md:py-1 md:rounded-[4px] md:rounded-br-[15px]">{{ad.images.length}}</span>
    </a>
    <!-- lables -->
    <a class="relative flex flex-col p-4 w-full md:cursor-pointer md:px-6" routerLink="/entry/{{ad.id}}">
      <div class="flex flex-row gap-2 items-center">
        <span class="text-[#161D26] text-sm font-medium line-clamp-2 md:text-lg" [title]="ad.title">{{ad.title}}</span>
        <fa-icon *ngIf="!ad.validated" class="text-[#F0811A]" icon="clock" title="Warte auf Freigabe"></fa-icon>
      </div>
      <span *ngIf="ad.category.subName !== 'Verschenken' && +ad.price > 0"
        class="absolute left-4 bottom-1 text-sm text-[#56BA90] font-medium md:left-6 md:bottom-0 md:text-xl md:font-semibold">€ {{ad.price | currency:'EUR':'':'1.0-0':'de-DE'}} {{ad.priceType === 'VHB' ? 'VHB' : '' }}</span>
      <div class="absolute right-4 bottom-1 grid grid-cols-[1fr_auto] gap-x-2 text-xs font-medium text-kaz-blue md:right-6">
        <span>Online:</span>
        <div class="text-right">{{ad.publicationDate[0] | date}}</div>
        <span>Endet:</span>
        <div class="text-right">{{ad.expired | date}}</div>
      </div>
      <div class="absolute bottom-2.5 right-5 h-5 flex flex-row gap-1 text-[#999999] text-xs font-medium items-center ">
        <fa-icon class="hidden" icon="eye" size="sm"></fa-icon>
        <span class="hidden mr-2">600</span>
        <fa-icon class="hidden" icon="heart" size="sm"></fa-icon>
        <span class="hidden">300</span>
      </div>
    </a>
  </div>
  <!-- Action Buttons -->
  <div class="grid grid-cols-3 gap-4 text-xs text-[#F0811A] font-medium justify-center items-center py-2 px-4 md:p-5">
    <!-- Reserve -->
    <div (click)="toggleReserved()"
         class="flex w-full justify-center py-1 rounded-full border md:cursor-pointer"
         [class]="ad.reserved ? 'bg-[#F0811A] text-white border-[#F0811A]' : 'border-[#F0811A]'">
      <span>{{ad.reserved ? 'Aktivieren' : 'Reservieren'}}</span>
    </div>
    <!-- Edit -->
    <div *ngIf="!editDisabled(ad)" class="flex grow justify-center w-full border border-[#F0811A] py-1 rounded-full md:cursor-pointer"
         routerLink="/entry/edit/{{ad.id}}">
      <span>Bearbeiten</span>
    </div>
    <!-- Edit - Disabled-->
    <div *ngIf="editDisabled(ad)" title="Bereits veröffentlicht" class="flex grow justify-center w-full border border-[#D2D2D2] text-white bg-[#D2D2D2] py-1 rounded-full">
      <span>Bearbeiten</span>
    </div>
    <!-- Löschen -->
    <div (click)="toggleDelete()"
         class="flex grow justify-center w-full border border-[#F0811A] py-1 rounded-full md:cursor-pointer"
         [class]="showDelete? 'bg-[#F0811A] text-white border-[#F0811A]' : 'border-[#F0811A]'">
      <span>Löschen</span>
    </div>
  </div>

  <!-- Delete -->
  <div *ngIf="showDelete"
       class="absolute flex flex-col gap-3 w-full h-full bg-black/90 text-white items-center justify-center">
    <span>Sicher?</span>
    <div class="flex flex-row gap-3 text-xs items-center md:gap-5">
      <div (click)="deleteAd()"
           class="flex grow justify-center w-24 border border-[#F0811A] bg-[#F0811A] py-1 rounded-full md:cursor-pointer">
        <span>Ja</span>
      </div>
      <div (click)="toggleDelete()"
           class="flex grow justify-center w-24 border border-[#F0811A] text-[#F0811A] py-1 rounded-full md:cursor-pointer">
        <span>Abbrechen</span>
      </div>
    </div>
  </div>
</div>
