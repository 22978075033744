<kaz-no-frame>
  <!-- Mail-Page -->
  <div *ngIf="!showPasswordPage" class="flex flex-col md:max-w-[488px] md:m-auto">
    <span class="text-xl text[#161D26] leading-7 font-semibold">Hallo</span>
    <span class="text-xs text[#161D26] mb-11 md:mb-16">
      Bei kleinanzeigen.bo.de anmelden oder <a class="text-[#224261] underline md:cursor-pointer"
                                             (click)="createWebUser()">Konto erstellen</a>
    </span>
    <!-- Mail-Mobile -->
    <div class="flex flex-col md:hidden">


      <kaz-input [(value)]="mail" (keyup.enter)="setMail()"
                 class="mb-8"
                 placeholder="E-Mail"></kaz-input>
      <kaz-button (onClick)="setMail()"
                  [disabled]=" !(mail.length > 5 && mail.includes('@') && mail.includes('.'))"
                  text="Weiter"></kaz-button>
    </div>
    <!-- Mail&Password-Desktop -->
    <div class="hidden md:flex md:flex-col">
      <div class="grid grid-cols-[auto_1fr] gap-8 items-center text-base text-[#222222] font-medium mb-8">
        <span>E-Mail</span>
        <kaz-input [(value)]="mail" (keyup.enter)="login()"
                   placeholder=""></kaz-input>
        <span>Passwort</span>
        <kaz-input [(value)]="password" (keyup.enter)="login()" inputType="password" [showEye]="true"
                   placeholder=""></kaz-input>
      </div>
      <span class="flex mb-16 self-center text-xs text-[#224261] underline cursor-pointer" (click)="resetPassword()">Passwort vergessen?</span>


      <kaz-button (onClick)="login()" class="self-center" width="343px"
                  [disabled]=" mail.length <= 5 || !mail.includes('@') || !mail.includes('.') || password.length <= 3"
                  text="Weiter"></kaz-button>
    </div>
    <kaz-divider class="mb-6 mt-[46px] md:my-16 hidden">oder</kaz-divider>
    <kaz-button class="mb-9 md:w-[343px] md:self-center hidden" text="Weiter mit Google" [disabled]="true"
                imagePath="assets/images/googleLogo.svg"
                buttonType="bordered"></kaz-button>
    <!-- Error-Text -->
    <span *ngIf="loginErrorText !== ''"
          class="hidden mt-4 text-center font-semibold text-base text-[#F0811A] md:flex">{{ loginErrorText }}</span>
    <div *ngIf="!showPasswordPage" class="flex gap-3 flex-row items-center justify-center mt-9">
      <input class="md:cursor-pointer" type="checkbox" id="coding" name="coding" value="coding" checked/>
      <label class="font-medium text-xs  md:cursor-pointer" for="coding">Eingeloggt bleiben</label>
    </div>


  </div>
  <!-- Password-Page -->
  <div *ngIf="showPasswordPage" class="flex flex-col md:max-w-[488px] md:m-auto md:hidden">
    <span class="text-xl text-[#161D26] leading-7 font-semibold">Willkommen</span>
    <div class="flex flex-col text-xs text-[#161D26] mb-5">
      <span class="mb-2">{{ mail }}</span>
      <span>
        Nicht Ihre E-Mail? <a class="text-[#224261] underline cursor-pointer"
                                 (click)="rewriteMail()">E-Mail wechseln</a>
      </span>
    </div>
    <kaz-input [(value)]="password" (keyup.enter)="login()" inputType="password" [showEye]="true"
               class="mb-8"
               placeholder="Passwort"></kaz-input>
    <kaz-button (onClick)="login()"
                [disabled]="!(password !== '' && password.length >= 3)"
                text="Anmelden"></kaz-button>
    <!-- Error-Text -->
    <span *ngIf="loginErrorText !== ''"
          class="mt-4 text-center font-semibold text-sm text-[#F0811A]">{{ loginErrorText }}</span>
    <div class="mt-10 mb-6 text-xs text-center text-[#224261]">
      <span class="underline mr-2 cursor-pointer"
            (click)="setShowResetButton()">Sie benötigen Hilfe beim Anmelden?</span>
      <span><fa-icon [icon]="showResetPassword?'chevron-up':'chevron-down'"></fa-icon></span>
    </div>
    <kaz-button *ngIf="showResetPassword" (onClick)="resetPassword()" buttonType="bordered"
                text="Passwort zurücksetzen"></kaz-button>

  </div>
</kaz-no-frame>


