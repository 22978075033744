<kaz-no-frame>
  <div class="flex flex-col mt-4 mb-12 md:max-w-[450px] md:m-auto">
    <div class="relative flex h-[400px] md:self-center md:w-[375px]">
      <img class="absolute" src="assets/images/path.svg" alt="Pfad">
      <div class="absolute right-6 top-24 h-20 w-20 rounded-full bg-[#F0811A]"></div>
      <img class="absolute right-0 top-20 opacity-50 w-52 h-52" src="assets/images/chest.svg" alt="Truhe">
      <fa-icon class="absolute text-[#244463] left-12 " icon="map-location-dot" size="4x"></fa-icon>
      <span class="absolute bottom-0 mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Sie sind auf der Suche nach einer Kleinanzeige in Ihrer Region? Dann melden Sie sich direkt an.
      </span>
    </div>
    <kaz-button routerLink="/signup" class="mt-12 md:w-[343px] md:self-center" buttonType="primary" text="Konto erstellen"></kaz-button>
    <a routerLink="/login" class="w-full text-center text-base text-[#F0811A] font-medium mt-5 md:w-[343px] md:self-center">Anmelden</a>
  </div>
</kaz-no-frame>
