import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {CookieService} from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {User} from "../interfaces/user";


interface JWTClaims {
  id: string;
  mail: string;
  userType: string;
  exp: number;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  claimCache: [string, JWTClaims] | undefined = undefined;

  selfCache: {[_:string]: User} = {};

  constructor(private router: Router,
              private cookie: CookieService
  ) {
  }

  isAuthenticated(): boolean {
    return ((this.getClaimsFromToken()?.exp ?? 0) * 1000) > new Date().getTime();
  }

  setAuth(accessToken: string): void {
    this.cookie.set('token', accessToken, new JwtHelperService().decodeToken(accessToken).exp * 1000, '/', environment.cookieBaseUrl);
  }

  clearAuth(): void {
    this.cookie.delete('token', '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
  }

  getToken(): string {
    return this.cookie.get('token');
  }

  getClaimsFromToken(): JWTClaims | null {
    const tok = this.getToken();
    if (this.claimCache !== undefined && tok === this.claimCache[0]) return this.claimCache[1];

    const claims = new JwtHelperService().decodeToken<JWTClaims>(tok);

    if (claims !== null) this.claimCache = [tok, claims];

    return claims;
  }

  getSelfID(): string|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.id;
  }

  getSelf(): User|null {
    let id = this.getSelfID();
    if (id === null) return null;
    let v = this.selfCache[id];
    if (v === undefined) return null;
    return v;
  }

  setSelf(s: string, data: User) {
    this.selfCache[s] = data;
  }
}
