<div *ngIf="ownChats !== null" class="flex flex-col">
  <div *ngIf="ownChats.chats.length === 0" class="flex flex-col">
    <img class="mx-16 mt-16" src="assets/images/404.svg" alt="404-Logo">
    <span
      class="mx-[84px] mt-8 font-medium text-base text-[#A7A7A7] text-center">Bisher noch ziemlich<br>leer hier.</span>
  </div>
  <span *ngIf="ownChats.chats.length > 0" class="hidden md:flex md:text-xl md:text-[#222222] md:font-semibold md:mb-7">Nachrichten</span>

  <div *ngIf="ownChats.chats.length > 0" class="flex md:grid md:grid-cols-[40%_1fr] md:bg-[#FAFAFA] md:w-full md:h-[486px] md:rounded-[8px] md:border md:border-[#D2D2D2] md:overflow-hidden">
    <div class="flex flex-col w-full md:max-h-[486px] md:overflow-y-auto md:border-r md:border-[#D2D2D2]">
      <div *ngFor="let chat of ownChats.chats">
        <kaz-card-chat class="bg-red-700" (click)="setActiveChat(chat)" [active]="activeChat" [chat]="chat"></kaz-card-chat>
      </div>
    </div>

    <!-- chat- history Desktop-->
    <div class="hidden md:relative md:flex">
      <!-- Chat-History -->
      <div #chatHistory class="flex flex-col gap-5 h-full w-full bg-[#FAFAFA] pb-[80px] p-4 overflow-y-auto md:max-h-[486px]">
        <div *ngFor="let msg of messages">
          <div *ngIf="isOwnMsg(msg.senderUserId)" class="flex justify-end">
            <span class="flex py-2 px-4 bg-[#F0811A] w-[70%] text-sm text-white border border-[#F0811A] rounded-t-lg rounded-bl-lg whitespace-pre-wrap">{{msg.message}}</span>
          </div>

          <span *ngIf="!isOwnMsg(msg.senderUserId)"
                class="flex py-2 px-4 bg-[#FEFEFE] w-[70%] text-sm text-[#222222] border border-[#F1F1F1] rounded-t-lg rounded-br-lg whitespace-pre-wrap">{{msg.message}}</span>
        </div>
      </div>

      <!-- Message -->
      <div class="absolute bottom-0 left-0  flex-row gap-4 w-full bg-[#FAFAFA] p-4 items-center"
      [class]="activeChat === null ? 'hidden' : 'flex'">
          <textarea [(ngModel)]="msg"
                    class="px-4 pt-3 h-12 grow leading-6 resize-none text-base text-[#161D26] font-medium border border-[#999999] rounded-[6px] bg-[#F7F7F7]"
                    placeholder="Nachricht"></textarea>
        <fa-icon (click)="sendMessage()" class="flex" icon="paper-plane-top" size="xl"></fa-icon>
      </div>
    </div>
  </div>
</div>
