<kaz-default-frame>
  <div class="flex flex-col mb-4 h-full items-center justify-center">
    <fa-icon class="text-red-700 my-20" icon="xmark" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
      Hoppla, etwas ist bei der Zahlung schiefgelaufen :(
    </span>
    <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Es geht sofort für Sie weiter.
    </span>
  </div>
</kaz-default-frame>
