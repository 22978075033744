import {Component, OnInit} from '@angular/core';
import {AdList} from "../../interfaces/ad";
import {CursorToken} from "../../interfaces/utils";
import {ApiService} from "../../services/api.service";
import {AuthenticationService} from "../../services/auth.service";

@Component({
  selector: 'kaz-archive',
  templateUrl: './kaz-archive.component.html',
  styleUrls: ['./kaz-archive.component.scss']
})

export class KazArchiveComponent implements OnInit {
  ownAds: AdList | null = null;

  nextToken: CursorToken = "@start";

  constructor(private _api: ApiService) {
  }

  async ngOnInit() {
    await this.getArchivedAds()
  }

  async getArchivedAds() {
    try {
      this.ownAds = await this._api.getArchivedAdList(this.nextToken, 24);
    } catch (err) {

    }
  }
}
