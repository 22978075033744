<kaz-default-frame>
  <div class="flex flex-col gap-8 text-sm text-[#222222] pb-6">
    <span class="font-semibold text-xl">Grundsätze</span>
    <div class="flex flex-col gap-4">
      <span class="font-semibold text-base">Was man für die Anzeigenschaltung wissen muss</span>
      <span>Produkte oder Dienstleistungen, die entweder gegen geltendes Recht oder gegen die Grundsätze von kleinanzeigen.bo.de verstoßen, werden von der Plattform entfernt.</span>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-semibold text-base">Generell gilt:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Schalten Sie Ihre Anzeige in dem Standort, an dem sich die Ware befindet, von dem aus Sie das
          Dienstleistungsangebot erbringen oder an dem der Beruf ausgeübt wird. Ihre Anzeige erscheint in dem
          ausgewählten Ort und in der Rubrik und ist auch durch die Schlagwort- oder Umkreissuche deutschlandweit
          auffindbar.&nbsp;
        </li>
        <li>Geben Sie Ihre Anzeige nur&nbsp;einmalig&nbsp;auf, Anzeigenduplikate sind daher nicht erlaubt.&nbsp;Hierzu
          zählen auch Anzeigen, die trotz Unterscheidung, dasselbe Angebot/Gesuch beinhalten.
        </li>
        <li>Fassen Sie Ihr Dienstleistungsangebot in einer Anzeige zusammen.</li>
        <li>Bitte bieten Sie ein konkretes Produkt, ein konkretes Stellenangebot oder eine konkrete Dienstleistung an.
          Das Produkt oder die Dienstleistung müssen in der Überschrift und im Anzeigentext klar beschrieben werden.
        </li>
        <li>Bieten Sie nur Produkte an, die sich in Ihrem Besitz befinden.</li>
        <li>Nutzen Sie nur eigene Fotos und Texte.</li>
        <li>Geben Sie einen realistischen Preis an.</li>
        <li>Verfassen Sie Ihre Anzeigen auf Deutsch.</li>
        <li>Derselbe Benutzer darf nur unter Verwendung einer E-Mail-Adresse Anzeigen aufgeben.&nbsp;</li>

      </ul>

      <span class="font-semibold text-base">Nicht erlaubt ist:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Versand als einzige Option zur Übergabe der Ware und Ausschluss einer persönlichen Abholung.</li>
        <li>Ein Link zu einer Internetseite darf im Anzeigentext lediglich erwähnt werden, wenn dieser als
          weiterführende Information zum angebotenen Artikel, der Dienstleistung oder dem Job, dient.
        </li>
        <li>Die Angabe einer Internetseite im Anzeigentext ist nicht gestattet, wenn:<br>
          -&nbsp;deren Inhalte gegen gesetzliche Vorschriften oder die gute Sitte verstoßen<br>
          -&nbsp;der Betreiber der Website nicht mit der Verlinkung einverstanden ist<br>
          -&nbsp;diese zu Auktionen, Kleinanzeigen oder allgemein Angeboten von Wettbewerbern von kleinanzeigen.bo.de
          führt.
        </li>
        <li>Die Angabe von Kontaktdaten wie den Facebookkonto, Skypenamen, Telefonnummer, u.ä. im Beschreibungstext.
          Bitte tragen Sie, wenn gewünscht, im dafür vorgesehenden Feld "Telefonnummer" unter "Anbieterdetails" Ihre
          Rufnummer ein.
        </li>
        <li>Das Schalten reiner Werbung für Webseiten, Portale oder Firmen</li>
        <li>Suchwort-Spam, d.h. die Anhäufung von Suchwörtern ohne direkten Zusammenhang zur Anzeige</li>
        <li>Das Schalten von Kontaktanzeigen und Tätigkeiten mit sexuellem Hintergrund.</li>
        <li>Es ist nicht gestattet, Nacktbilder oder pornografische Bilder in Anzeigen einzufügen.</li>
        <li>Bitte achten Sie auf die Formulierung des Anzeigentextes und vermeiden Sie eine unangemessene
          Ausdrucksweise, Beschimpfungen, Warnungen, Hinweise oder Aufrufe.
        </li>
        <li>Eine kostenpflichtige Anzeige, die gegen die Grundsätze von kleinanzeigen.bo.de verstößt, wird gelöscht.
          Werden kostenpflichtige Anzeigen aufgrund eines Verstoßes von der Seite entfernt, werden die Gebühren für die
          Anzeige nicht erstattet.
        </li>
        <li><strong>Gewerbliche Anbieter:&nbsp;Das Schalten von gewerblichen Anzeigen ist untersagt.</strong></li>

      </ul>
      <span>Achtung! Diese Liste erhebt keinen Anspruch auf Vollständigkeit. Als Nutzer sind Sie selbst für Ihr Handeln auf der Plattform verantwortlich. Bitte achten Sie auf die geltenden gesetzlichen Bestimmungen bezüglich Ihrer Angebote.</span>


      <span class="font-semibold text-base">Es ist untersagt, folgende Produkte, Stellenangebote oder Dienstleistungen anzubieten oder in Anzeigen zu beschreiben:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Aufrufe zu Straftaten (z. B. Beleidigungen, Gewalttaten)</li>
        <li>Aufruf zu Geldspenden</li>
        <li>Beleidigungen, Beschimpfungen, Rufmord, Diskriminierung</li>
        <li>Nationalsozialistische, Rassenhass verbreitende oder verfassungsfeindliche Propaganda</li>
        <li>gemeingefährliche Aktivitäten</li>
        <li>Online Anzeigen mit ähnlichem Inhalt, wie&nbsp;z.B. "Kaufe alles..", "Suche Schmuck, Pelze,.." , "Sammle
          Besteck, altes Geschirr,.", usw.
        </li>
      </ul>

      <span class="font-semibold text-base">Dienstleistungen & Jobs</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Angebote im Zusammenhang mit Gewinnspielen, Lotto- und Roulettesystemen</li>
        <li>Angebote im Zusammenhang mit Network-Marketing und/oder Multi Level Marketing (MLM), Direktverkaufssystemen,
          Empfehlungsmarketing
        </li>
        <li>Finanz- oder sonstige Dienstleistungsangebote ohne Schufaprüfung</li>
        <li>Stellenangebote und -gesuche unter 14 Jahren</li>
        <li>Private Angebote von Model Agenturen, für Modeltraining, von Fotografen, für Vorsprechen, für Casting usw.
          und gewerbliche Anzeigen&nbsp;
        </li>

      </ul>

      <span class="font-semibold text-base">Jugendschutz</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Pornografische und jugendgefährdende Schriften oder Gegenstände, sowie Dienstleistungen</li>
        <li>Prostitution</li>
        <li>Angebote von sexuellen Hilfsmitteln, bzw. Gegenständen die der sexuellen Erregung dienen (z.B. Vibratoren,
          Massagestäbe, Dildos, SM- Zubehör)
        </li>
        <li>Medien mit der Kennzeichnung "Keine Jugendfreigabe", "Freigabe ab 18", "FSK 18", "USK 18"<br>
          Informieren Sie sich auf den folgenden Seiten, ob Ihr Medium einer Jugendfreigabe unterliegt:&nbsp;<br><a
            href="http://www.fsk.de" target="_blank">www.fsk.de</a>,&nbsp;<a href="http://www.usk.de" target="_blank">www.usk.de</a>
        </li>
        <li>Artikel mit einer Altersfreigabe ab 18 Jahren, Tabakwaren aller Art, sowie entsprechendes Zubehör wie z.B.
          Shishas oder Pfeifen
        </li>
        <li>Alkoholische Getränke</li>
        <li>Drogen im Sinne des Betäubungsmittelgesetzes und andere bewusstseinsverändernde Stoffe, sowie entsprechendes
          Zubehör
        </li>

      </ul>

      <span class="font-semibold text-base">Produkte</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Gefährliche oder eingeschränkt handelbare Gegenstände</li>
        <li>Gestohlene Gegenstände</li>
        <li>Alle Gegenstände mit Symbolen verfassungsfeindlicher Organisationen - auch nicht sichtbar dargestellte
          (verdecktes Hakenkreuz)
        </li>
        <li>Waffen im Sinne des Waffengesetzes, insbesondere Schuss-, Hieb- und Stichwaffen jeglicher Art, sowie Zubehör
          und Munition jeglicher Art
        </li>
        <li>Nachahmungen und Fälschungen (Geld, Briefmarken, Bekleidung, Elektronikartikel, Accessoires Produkte,
          etc.)
        </li>
        <li>Ungenehmigte Kopien geschützter Werke oder Gegenstände, insbesondere Kopien von Filmen, Videospielen,
          Büchern (ebooks) oder Musik
        </li>
        <li>Paintball inkl. Ausrüstung (Kleidung ist erlaubt)</li>
        <li>Das Anbieten von, sowie der Handel mit Elektroschrott gemäß Elektrogesetz (ElektroG)</li>
        <li>Das Anbieten von, sowie der Handel mit Schrott für private Nutzer</li>
        <li>Gegenstände, Kleidungsstücke und Ausweise aus sicherheitsrelevanten Bereichen (z. B. behördliche Ausweise
          und behördlich getragene Uniformen)
        </li>
        <li>Medizinische Instrumente (u.a. Röntgengeräte, Defilibrator, Ultraschall-/Sonographiegeräte)</li>
        <li>Arzneimittel (apothekenpflichtig und freiverkäuflich), Diätpräparate/Nahrungsergänzungsmittel sowie
          Medikamente für Tiere
        </li>
        <li>Parfum, außer leere Flakons, Factice</li>
        <li>Radioaktive Stoffe, Gift- und Explosivstoffe (Feuerwerkskörper, pyrotechnische Gegenstände), sowie sonstige
          gesundheitsschädigende Chemikalien
        </li>
        <li>Gaskartuschen oder Gasgrill mit Gaskartuschen, Gasflaschen (leer und voll)</li>
        <li>Pestizide</li>
        <li>Airbags (ausgebaute Airbags, sowie Lenkräder mit Airbags)</li>
        <li>Menschliche Organe, Blut, sowie andere Körperflüssigkeiten</li>
        <li>Listen, Verzeichnisse o. ä., die Hersteller-, Händler- und Lieferantenadressen enthalten</li>
        <li>Gebührenpflichtige Telefondienste (SMS-Dienste, 0900-Nummern, Faxabrufe etc.)</li>
        <li>Bootleg-Aufnahmen (Raubpressungen)</li>
        <li>Angebote zum Herunterladen von Medien</li>
        <li>Spielekontos, Spielepunkte &amp; -coins</li>
        <li>E-Books</li>
        <li>Hard- oder Software zum Decodieren von Pay-TV</li>
        <li>Hard- oder Software zum Modifizieren von Spielekonsolen oder Mobilfunktelefonen</li>
        <li>Wertpapiere (insbesondere Aktien), Geldmarkt- oder Finanzinstrumente, Kredite, Darlehen und
          Finanzierungshilfen, es sei denn, diese werden von Kreditinstituten mit Sitz oder Niederlassung im Inland, mit
          Adressangabe, angeboten
        </li>
        <li>Schuldscheine und gerichtliche Titel, sowie andere Forderungen aus Rechtsgeschäften</li>
        <li>Archäologische Funde</li>
        <li>Handel mit Elfenbein und allen Elfenbeinprodukten, und zwar unabhängig von der Herkunft des Elfenbeins oder
          Elfenbeinproduktes inkl. prähistorisches Elfenbein.
        </li>

      </ul>
      <span>Der Handel mit Nahrungsmitteln unterliegt besonderen gesetzlichen Bestimmungen. Bitte erkundigen Sie sich vor dem Anbieten, welche gesetzlichen Bestimmungen beachtet werden müssen.</span>
      <span>Bundesministerium der Justiz: Lebensmittel-, Bedarfsgegenstände- und Futtermittelgesetzbuch: http://www.gesetze-im-internet.de/lfgb/</span>

      <span class="font-semibold text-base">Grundsätze für Tierverkauf</span>
      <span>Der Verkauf von folgenden Tieren ist nicht zulässig:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>Tiere, die sich im Ausland befinden</li>
        <li>Hunde und Katzen, die jünger als 8 Wochen sind</li>
        <li>Lebende Tiere streng geschützter Tierarten, Produkte und Präparate streng geschützter Tierarten, sowie streng geschützte Pflanzen und deren Präparate. Die Einstufung einer Tierart als streng geschützt, kann beispielsweise in der Artenschutzdatenbank des Bundesamt für Naturschutz in Bonn nachgeschlagen werden:&nbsp;<a href="http://www.wisia.de" target="_blank">www.wisia.de</a></li>
        <li>Giftige, aggressive, kupierte und schwierig zu haltende Tiere</li>
        <li>Wildfänge</li>
        <li>Handel mit Lebendtieren als Lebendfutter</li>
        <li>Generell ist es nicht erlaubt, Tiere zu verschenken. Hier bedarf es einer Schutzgebühr.</li>
      </ul>



      <span class="font-semibold text-base">Grundsätze für Hunde & Welpenverkauf</span>
      <span>Der Verkauf von Hunden und Welpen ist nicht zulässig, wenn:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>es sich um sogenannte Listenhunde (auch Mischlinge) handelt</li>
        <li>Hunde zu verschenken sind</li>
        <li>Hunde&nbsp;jünger als 8 Wochen sind</li>
        <li>Züchter, mehr als 3 Würfe pro Jahr von 2 Rassen anbieten</li>
        <li>private Nutzer, mehr als eine Anzeige pro Jahr schalten</li>
      </ul>
      <span>Bitte informieren Sie sich auch bei <a class="underline text-[#F0811A]" href="http://www.wuehltischwelpen.de/checkliste-welpenkauf.html">www.wuehltischwelpen.de/checkliste-welpenkauf.html</a></span>
    </div>
  </div>
</kaz-default-frame>
