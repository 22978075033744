import {Component, Input} from '@angular/core';
import {Ad} from "../../interfaces/ad";
import {ApiService} from "../../services/api.service";
import {getDummyPicture} from "../../utils/dummyImages";
import {setPreviousAd} from "../../utils/sessionStorage";
import {Router} from "@angular/router";

@Component({
  selector: 'kaz-card-archive-ads',
  templateUrl: './kaz-card-archive-ads.component.html',
  styleUrls: ['./kaz-card-archive-ads.component.scss']
})
export class KazCardArchiveAdsComponent {
  @Input() ad: Ad | null = null;

  constructor(private _api: ApiService,
              private _router: Router,
              ) {
  }

  async ngOnInit() {
  }

  async reCreatAd(ad: Ad) {
    setPreviousAd(ad);
    this._router.navigate(['/entry/create']).then();
  }

  protected readonly getDummyPicture = getDummyPicture;

}
