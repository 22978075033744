<kaz-default-frame>
  <!--  Search  -->
  <div class="flex flex-col md:hidden">
    <kaz-search></kaz-search>
    <!-- Divider -->
    <div class="my-4 border-t border-[#EFEFEF]"></div>
  </div>
<div *ngIf="ads.length > 0" class="flex flex-col md:flex-row gap-4">
  <kaz-fast-search></kaz-fast-search>
  <div class="flex flex-col gap-4">
    <div *ngFor="let ad of ads">
      <kaz-card-default class="mb-6" [ad]="ad"></kaz-card-default>
    </div>
    <kaz-button *ngIf="nextToken !== '@end'" [isLoading]="loadingAds" (onClick)="loadMore()" class="mt-4 md:w-[343px] md:self-center mb-12" buttonType="bordered" text="Mehr laden"></kaz-button>
  </div>
</div>
  <!--  Kein Suchergebnis -->
  <div *ngIf="ads.length <= 0 && showEmpty" class="flex flex-col gap-4 mb-4 h-full items-center justify-center">
    <fa-icon class="text-[#F0811A] my-20" icon="empty-set" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
      Wir haben für Ihre Suche leider keine Suchergebnisse gefunden
    </span>

    <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Versuche es mit einem andern Wort
    </span>
  </div>

</kaz-default-frame>
