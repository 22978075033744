import {HttpClient} from '@angular/common/http';
import {lastValueFrom, map} from 'rxjs';
import {environment} from "../../environments/environment";
import {ApiService} from "../services/api.service";
import {AuthenticationService} from "../services/auth.service";
import {SerializedParamCollection, SerializedParamUtil} from "./serializedParameter";

export enum APIRequestMethod {'GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'COUNT'}

interface Parameter {
  [_:string]: string|number|boolean|undefined;
}

export interface APIServiceRef {
  http: HttpClient;
  auth: AuthenticationService;
  api: ApiService;
}

export class APIMethod<T> {

  method: APIRequestMethod;
  uri: string;

  constructor(m: APIRequestMethod, u: string) {
    this.method = m;
    this.uri = u;
  }

  private rstr(): string {
    switch (this.method) {
      case APIRequestMethod.DELETE: return 'DELETE';
      case APIRequestMethod.GET:    return 'GET';
      case APIRequestMethod.POST:   return 'POST';
      case APIRequestMethod.PUT:    return 'PUT';
      case APIRequestMethod.PATCH:  return 'PATCH';
      case APIRequestMethod.COUNT:  return 'COUNT';
    }
  }

  run(ref: APIServiceRef, params: Parameter, body: any|null, filter?: SerializedParamCollection): Promise<T> {
    let url = this.url(ref, params, filter);

    const prom = ref.http.request<T>(this.rstr(), url, {body: body, observe: 'response', headers: {"Authorization": "Bearer " +  ref.auth.getToken()}}).pipe(map(data => {

      if (this.uri !== 'refresh' && data.headers.has('X-JWT-OUTDATED')) setTimeout(() =>
      {
        console.log("refresh token due to [X-JWT-OUTDATED]");
        ref.api.refreshToken().then(() => { console.log("Token refreshed."); });
      }, 100);

      return data.body!;
    }));

    return lastValueFrom(prom);
  }

  url(ref: APIServiceRef, params: Parameter, filter?: SerializedParamCollection): string {
    let urlbase = this.uri;
    let urlext = '';

    let hasParams = false;
    if (params !== null) {
      for (const key of Object.keys(params)) {
        const val = params[key];
        if (val === undefined) continue;
        if (key.startsWith(':') && urlbase.includes('{'+key.substring(1)+'}')) {
          urlbase = urlbase.replace('{'+key.substring(1)+'}', `${val}`);
          continue;
        }

        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(val);
        hasParams = true;
      }
    }
    if (filter !== undefined) {
      const fp = SerializedParamUtil.ToAPIParams(filter);
      for (const key of Object.keys(fp)) {
        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(fp[key]);
        hasParams = true;
      }
    }

    return environment.apiBaseUrl+urlbase+urlext;
  }

}
