import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {User} from "../../interfaces/user";
import {AuthenticationService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'kaz-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: User | null = null;

  navBar = [
    {name: 'Anzeigen', current: true},
    {name: 'Nachrichten', current: false},
    {name: 'Archiv', current: false},
    //{name: 'Favoriten', current: false},
  ];

  constructor(private _api: ApiService,
              private _auth: AuthenticationService) {
  }

  ngOnInit() {
    this.getOwnUser().then();
  }

  private async getOwnUser() {
    const userId = this._auth.getClaimsFromToken()?.id
    if (userId !== undefined)
      this.profile = await this._api.getOwnUser(userId);
  }

  getInitials() {
    if (this.profile !== null && this.profile !== undefined) {
      return this.profile.firstname[0] + this.profile.lastname[0];
    } else {
      return "--"
    }
  }

  setAsActive(navName: string) {
    for (const tab of this.navBar) {
      tab.current = tab.name === navName;
    }
  }

  isActive(navName: string) {
    let nav = this.navBar.find(item => item.name === navName)
    return nav !== undefined && nav.current;
  }
}
