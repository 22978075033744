import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'kaz-card-category-hint',
  templateUrl: './card-category-hint.component.html',
  styleUrls: ['./card-category-hint.component.scss']
})
export class CardCategoryHintComponent {
  showHint: boolean = true;
  @Output() closeHintOnEmit = new EventEmitter();
  @Input() category: 'CAR' | 'PROPERTY' | 'JOB' | 'NONE' = 'NONE';

  constructor() {
  }

  closeHintInfo() {
    this.showHint = false;
    this.closeHintOnEmit.emit();
  }

}
