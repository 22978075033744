import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {slideAnimation} from "../../../animations/slide.animations";
import {Ad, CreateAd} from "../../../interfaces/ad";
import {RFC3339} from "../../../interfaces/datetime";
import {ApiService} from "../../../services/api.service";
import {Base64} from "../../../interfaces/utils";
import {AuthenticationService} from "../../../services/auth.service";
import {Cipher} from "../../../interfaces/cipher";
import {Router} from "@angular/router";
import {User} from "../../../interfaces/user";
import {dateReplacements, getCategoryList, getLocalityList, getSubCategoryList} from 'src/app/utils/globals';
import {BillingAddress, emptyBillingAddress} from "../../../interfaces/address";
import {isValidIBANNumber} from "../../../utils/iban";
import {DateUtils} from "../../../utils/date";
import {clearPreviousAd, getPreviousAd} from "../../../utils/sessionStorage";
import {NgxImageCompressService} from "ngx-image-compress";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Component({
  selector: 'kaz-create-ad',
  templateUrl: './create-ad.component.html',
  styleUrls: ['./create-ad.component.scss'],
  animations: [slideAnimation],
})
export class CreateAdComponent implements OnInit {
  @ViewChild('imageInput', {static: false}) imageInput!: ElementRef;

  showCipher: boolean = false;
  showAdInfo: boolean = false;

  showAdPreview: boolean = false;

  allAvailableDates: { publicationDate: Date, expirationDate: Date }[] = [];
  imageIndex: number = 0;
  titleImageIndex: number = 0;
  images: string[] = [];

  imageData: Base64[] = [];
  imageContentType: string[] = [];

  adType: "OFFER" | 'WANTED' | 'GOOD_DEED' = 'OFFER';
  title: string = '';
  description: string = '';
  cipherType: 'NONE' | 'CHAT' | 'LETTER' | 'OFFICE' = 'NONE';
  priceType: "FP" | "VHB" = 'FP';
  price: string = '0';
  publicationDates: RFC3339[] = [];

  ad: Partial<CreateAd> | null = null;

  isLoading: boolean = false;

  placementFee: number = 0;
  cipherFee: number = 0;

  categoryName: string = '';
  subCategoryName: string = '';
  localityName: string = '';

  categoryHint: 'CAR' | 'PROPERTY' | 'JOB' | 'NONE' = 'NONE';

  categoryList: string[] = getCategoryList();
  subCategoryList: { masterCategory: string, name: string }[] = getSubCategoryList();
  localityList: string[] = getLocalityList();

  showCategoryHint: boolean = false;

  firstnameCipher: string = '';
  lastnameCipher: string = '';
  streetCipher: string = '';
  houseNumberCipher: string = '';
  cityCipher: string = '';
  zipCipher: string = '';

  billingAddress: BillingAddress = emptyBillingAddress();

  cipher: Partial<Cipher> | null = null;
  ownUser: User | null = null;

  user: Partial<User> | null = null;

  publicationDateAmount: number = 4;

  isWrongImageTag: boolean = false;
  isBigImageFile: boolean = false;
  previousAdId: string | null = null;

  transferOldImages: boolean = true;

  constructor(private _api: ApiService,
              private _compress: NgxImageCompressService,
              private _auth: AuthenticationService,
              private _router: Router) {
  }

  async ngOnInit() {
    this.getPreviousAd();
    this.allAvailableDates = this.calculatePublicationDates();
    await this.getOwnUser();
  }

  getWeekDayName(date: Date): string {
    return DateUtils.getWeekday(date, false);
  }

  private calculateExpireDate(date: Date): Date {
    if (date.getDay() === 3) {
      const expDate = new Date(date);
      expDate.setDate(expDate.getDate() - 1);
      expDate.setHours(10, 0, 0, 0)
      return expDate
    } else {
      const expDate = new Date(date);
      expDate.setDate(expDate.getDate() - 2);
      expDate.setHours(15, 0, 0, 0)
      return expDate
    }
  }

  private calculatePublicationDates(): { publicationDate: Date, expirationDate: Date }[] {
    const today = DateUtils.today();
    const now = DateUtils.now();
    const weekdaysToAdd = [6, 3]; // 6 for Saturday, 3 for Wednesday

    let publicationDates: { publicationDate: Date, expirationDate: Date }[] = [];

    const dateIter = new Date(today);
    while (publicationDates.length < this.publicationDateAmount) {

      let d0 = new Date(dateIter);
      let d1 = this.calculateExpireDate(new Date(dateIter));

      for (const repl of dateReplacements) {
        if (DateUtils.dateEquals(d0, repl.publicationOld) && DateUtils.dateEquals(d1, repl.expiryOld)) {
          d0 = repl.publicationNew;
          d1 = repl.expiryNew;
        }
      }
      //Set time of publicationDate to 6am o'clock
      // d0.setHours(6,0,0,0);
      if (weekdaysToAdd.includes(dateIter.getDay()) && d1.getTime() > now.getTime()) {
        publicationDates.push({publicationDate: d0, expirationDate: d1});
      }

      dateIter.setDate(dateIter.getDate() + 1); // Move to the next day
    }

    return publicationDates;
  }

  async createAd() {
    try {
      this.isLoading = true;
      if (this.price === null || this.price == "")
        this.price = "0";

      this.images.forEach(base64String => {
        const parts = base64String.split(',');

        if (parts.length === 2) {
          const header = parts[0];
          const base64 = parts[1];

          const contentType = header.split(':')[1].split(';')[0];

          this.imageContentType.push(contentType);
          this.imageData.push(base64);
        }
      });

      const cat = this.categoryList.find(category => category === this.categoryName);
      const sCat = this.subCategoryList.find(sub => sub.name === this.subCategoryName);

      if (cat !== undefined && sCat !== undefined) {
        switch (this.cipherType) {
          case "CHAT":
          case "NONE":
            this.cipher = {
              type: this.cipherType,
            };
            break;
          case "LETTER":
            this.cipher = {
              type: this.cipherType,
              firstname: this.firstnameCipher,
              lastname: this.lastnameCipher,
              address: {
                street: this.streetCipher,
                number: this.houseNumberCipher,
                zip: this.zipCipher,
                city: this.cityCipher,
              }
            };
            break;
          case "OFFICE":
            this.cipher = {
              type: this.cipherType,
              firstname: this.firstnameCipher,
              lastname: this.lastnameCipher,
            };
        }
        this.ad = {
          transferOldImages: this.transferOldImages,
          imageData: this.imageData,
          imageContentType: this.imageContentType,
          titleImageIndex: this.titleImageIndex,
          category: {
            name: cat,
            subName: sCat.name,
            localityName: this.localityName,
          },
          adType: this.adType,
          title: this.title,
          description: this.description,
          cipher: this.cipher,
          priceType: this.priceType,
          price: +this.price,
          publicationDate: this.publicationDates,
          billingAddress: this.billingAddress,
        }

        if (this.previousAdId !== null) {
          this.ad.previousAdId = this.previousAdId;
        }
        //Create ad
        const data = await this._api.createAd(this.ad);

        // TODO-1 wieder einfügen machen
        // if (data.adType !== "GOOD_DEED") {
        //   window.location.href = await this._api.createAdCheckoutSession(data.id, data.authorId);
        // } else {
        //   this._router.navigate(['profile']).then();
        // }

        this._router.navigate(['profile']).then(); //TODO-1 wieder entfernen machen


        this.isLoading = false;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  onAdTypeChange(typ: "OFFER" | "WANTED" | "GOOD_DEED") {
    this.adType = typ;
    this.price = "";
    this.publicationDates = [];
    this.cipherType = "NONE";
    this.placementFee = 0;
    this.cipherFee = 0;
    this.categoryName = '';
    this.subCategoryName = '';
    this.localityName = '';
  }

  onAdPriceTypeChange(typ: "FP" | "VHB") {
    this.priceType = typ;
  }

  onAdCipherTypeChange(typ: 'CHAT' | 'LETTER' | 'OFFICE') {
    if (this.cipherType !== typ)
      this.cipherType = typ;
    else
      this.cipherType = 'NONE';


    switch (this.cipherType) {
      case "OFFICE":
      case "LETTER":
        this.cipherFee = 8;
        break;
      case "CHAT":
      case "NONE":
      default:
        this.cipherFee = 0;
    }

    if (this.adType === 'GOOD_DEED') {
      this.cipherFee = 0;
    }

  }

  toggleDate(date: RFC3339) {
    if (this.publicationDates.includes(date)) {
      this.publicationDates = this.publicationDates.filter(d => d !== date);
      if (this.adType !== 'GOOD_DEED')
        this.placementFee -= 12.5;
    } else {
      this.publicationDates.push(date);
      if (this.adType !== 'GOOD_DEED')
        this.placementFee += 12.5;
    }
  }

  onSelectImage() {
    this.isWrongImageTag = false;
    this.isBigImageFile = false;
    this.imageInput.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
      if (fileSizeMB > 12) {
        this.isBigImageFile = true;
        return;
      }

      const reader = new FileReader();
      let image: string = '';

      reader.onload = (e) => {
        image = e.target?.result as string;
        if (image !== '' && input.files !== null && this.endsWithImageTag(input.files[0])){
          this._compress.compressFile(image, -1, 100, 90,1024,1024).then(img => {
            this.images.push(img);
          }).catch(err => {
            console.error('Komprimierung fehlgeschlagen: ',err);
          });
        }
        else {
          this.isWrongImageTag = true;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  private endsWithImageTag(file: File) {
    return file.name.toLowerCase().endsWith('.png') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.jpg')
  }

  deleteImage(image: string) {
    const index = this.images.indexOf(image);
    if (index !== -1) {
      //entferne Bild
      this.images.splice(index, 1);

      if (index === this.titleImageIndex) {
        this.titleImageIndex = 0;
        this.imageIndex = this.titleImageIndex
      }
      if (index < this.titleImageIndex)
        this.titleImageIndex -= 1;
    }
  }

  setAsTitleImage(image: string) {
    const index = this.images.indexOf(image);
    if (index !== -1)
      this.titleImageIndex = index;
  }

  setImageIndex(image: string) {
    const index = this.images.indexOf(image);
    if (index !== -1)
      this.imageIndex = index;
  }

  isFormIncomplete(): boolean {
    switch (this.cipherType) {
      case "OFFICE":
        return (
          this.title === '' ||
          this.categoryName === '' ||
          this.subCategoryName === '' ||
          this.description === '' ||
          this.publicationDates.length === 0 ||
          this.firstnameCipher === '' ||
          this.lastnameCipher === '' ||
          (this.localityName === '' && this.subCategoryName === 'Vermietungen') ||
          this.isLoading
        );
      case "LETTER":
        return (
          this.title === '' ||
          this.categoryName === '' ||
          this.subCategoryName === '' ||
          this.description === '' ||
          this.publicationDates.length === 0 ||
          this.firstnameCipher === '' ||
          this.lastnameCipher === '' ||
          this.streetCipher === '' ||
          this.houseNumberCipher === '' ||
          this.cityCipher === '' ||
          this.zipCipher === '' ||
          (this.localityName === '' && this.subCategoryName === 'Vermietungen') ||
          this.isLoading
        );
      default:
        return (
          this.title === '' ||
          this.categoryName === '' ||
          this.subCategoryName === '' ||
          this.description === '' ||
          this.publicationDates.length === 0 ||
          (this.localityName === '' && this.subCategoryName === 'Vermietungen') ||
          this.isLoading
        );
    }
  }

  getFilteredSubCategoryList() {
    if (this.categoryName === 'Job')
      this.subCategoryName = 'Stellen'
    return this.subCategoryList.filter(subCategory => subCategory.masterCategory === this.categoryName && this.subCategoryForAdType(subCategory));
  }

  formatInput(event: any) {
    // Entfernen alle Nicht-Ziffern aus Eingabe
    const input = event.target.value.replace(/[^0-9]/g, '');

    if (input == '')
      this.price = "0";
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      return;
    }

    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  setCategoryHint() {
    switch (this.categoryName) {
      case 'Immobilien':
        this.categoryHint = 'PROPERTY';
        break;
      case 'Job':
        this.categoryHint = 'JOB';
        break;
      case 'Mobiles':
      case 'Auto':
        this.categoryHint = 'CAR';
        break;
      default:
        this.categoryHint = 'NONE';
        break;
    }
    this.showCategoryHint = true;
  }

  checkPrice(event: any) {
    if (event.target.value === "0")
      this.price = '';
  }

  clearCipher() {
    this.firstnameCipher = '';
    this.lastnameCipher = '';
    this.streetCipher = '';
    this.houseNumberCipher = '';
    this.cityCipher = '';
    this.zipCipher = '';
  }

  async fillCipher() {
    if (this.ownUser !== null && this.ownUser.cipher !== null) {
      console.log(this.ownUser.cipher);
      switch (this.cipherType) {
        case "OFFICE":
          this.firstnameCipher = this.ownUser.cipher.firstname;
          this.lastnameCipher = this.ownUser.cipher.lastname;
          break;
        case "LETTER":
          this.firstnameCipher = this.ownUser.cipher.firstname;
          this.lastnameCipher = this.ownUser.cipher.lastname;
          this.streetCipher = this.ownUser.cipher.address.street;
          this.houseNumberCipher = this.ownUser.cipher.address.number;
          this.cityCipher = this.ownUser.cipher.address.city;
          this.zipCipher = this.ownUser.cipher.address.zip;
          break;
        case "CHAT":
        case "NONE":
          break;
      }
    }
  }

  areCipherEmpty(): boolean {
    switch (this.cipherType) {
      case 'OFFICE':
        return this.firstnameCipher === '' && this.lastnameCipher === '';
      case 'LETTER':
        return this.firstnameCipher === '' && this.lastnameCipher === '' && this.streetCipher === '' && this.houseNumberCipher === '' && this.cityCipher === '' && this.zipCipher === '';
      case 'CHAT':
      case 'NONE':
        return true;
    }
  }

  private async getOwnUser() {
    try {
      const ownId = this._auth.getSelfID();
      if (ownId !== null) {
        this.ownUser = await this._api.getOwnUser(ownId);
        if (this.ownUser.billingAddress !== null) {
          this.billingAddress.firstname = this.ownUser.billingAddress.firstname;
          this.billingAddress.lastname = this.ownUser.billingAddress.lastname;
          this.billingAddress.street = this.ownUser.billingAddress.street;
          this.billingAddress.number = this.ownUser.billingAddress.number;
          this.billingAddress.zip = this.ownUser.billingAddress.zip;
          this.billingAddress.city = this.ownUser.billingAddress.city;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  private subCategoryForAdType(subCategory: { masterCategory: string; name: string }) {
    if (this.adType !== "GOOD_DEED") {
      if (this.adType !== "WANTED" && subCategory.name === 'Bekanntschaften')
        return false;
      return subCategory.name !== 'Entlaufen/Entflogen' &&
        subCategory.name !== 'Gefunden' &&
        subCategory.name !== 'Verloren' &&
        subCategory.name !== 'Gute Tat' &&
        subCategory.name !== 'Zugelaufen/Zugeflogen' &&
        subCategory.name !== 'Tierschutz' &&
        subCategory.name !== 'Verschenken';
    } else {
      return subCategory.name === 'Entlaufen/Entflogen' ||
        subCategory.name === 'Gefunden' ||
        subCategory.name === 'Verloren' ||
        subCategory.name === 'Gute Tat' ||
        subCategory.name === 'Zugelaufen/Zugeflogen' ||
        subCategory.name === 'Tierschutz' ||
        subCategory.name === 'Verschenken';
    }
  }

  getCatList() {
    if (this.adType === 'GOOD_DEED') {
      this.categoryName = 'Dies & Das';
      return this.categoryList.filter(category => category !== 'Job' && category !== 'Immobilien' && category !== 'Mobiles');
    } else {
      return this.categoryList;
    }
  }

  addPublicationDateAmount() {
    if (this.publicationDateAmount <= 8) {
      this.publicationDateAmount += 4;
      this.allAvailableDates = [];
      this.allAvailableDates = this.calculatePublicationDates();
    }
  }

  billingAddressInvalid() {
    return this.billingAddress.firstname === "" ||
      this.billingAddress.lastname === "" ||
      this.billingAddress.street === "" ||
      this.billingAddress.number === "" ||
      this.billingAddress.zip === "" ||
      this.billingAddress.city === "" ||
      (this.adType !== 'GOOD_DEED' && this.billingAddress.bank === '') ||
      (this.adType !== 'GOOD_DEED' && this.billingAddress.iban === '') ||
      (this.adType !== 'GOOD_DEED' && !this.isValidIban());
  }

  private isValidIban() {
    return isValidIBANNumber(this.billingAddress.iban);
  }

  private getPreviousAd() {
    const previousAd: Ad | null = getPreviousAd();
    if (previousAd !== null) {
      this.previousAdId = previousAd.id;
      this.titleImageIndex = previousAd.titleImageIndex;
      this.categoryName = previousAd.category.name;
      this.subCategoryName = previousAd.category.subName;
      this.localityName = previousAd.category.localityName;
      this.adType = previousAd.adType;
      this.title = previousAd.title;
      this.description = previousAd.description;
      this.cipherType = previousAd.cipher.type;
      this.priceType = previousAd.priceType;
      this.price = previousAd.price.toString();

      clearPreviousAd();
    }
  }

  changeTransferOldImages(event: any) {
    this.transferOldImages = event.target.checked;
  }
}
