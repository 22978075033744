<div *ngIf="showAdInfo" class="fixed top-0 left-0 w-full h-full bg-black/70 z-10">
  <div
    class="fixed flex flex-col bottom-0 left-0 w-full bg-[#F0811A] p-[18px] text-white rounded-t-[20px] shadow-md transition-transform md:left-1/2 md:transform md:-translate-x-1/2 md:max-w-[1080px] md:rounded-[20px] md:bottom-8">
    <div class="flex flex-col font-normal text-sm mb-8">
    <span class="font-semibold text-base">
      Nur 12,50 EUR pro Erscheinungstag inkl. Bild
    </span>
      <span class="mb-2">
      Die Rubrik <span class="font-bold">Gute Tat</span> ist für Sie kostenlos*.
    </span>
      <span class="mb-2">
      Die Printanzeige wird ggf. ohne zusätzlich beigefügte Materialien gedruckt (Ausnahme: Bild einer Kleinanzeige) und erscheint ggf. in einer anderslautenden Rubrik als im Portal wenn diese im Zielmedium (Zeitung) so nicht vorhanden ist.
    </span>
      <span class="mb-4">
      Der Verlag behält sich vor die Anzeige zu kürzen.
    </span>
      <span class="text-base font-bold">
      Kleinanzeige mit Chiffre
    </span>
      <ul class="mb-2">
        <li>Chiffre per Abholung im Kundencenter: 8,00 EUR</li>
        <li>Chiffre mit postalischer Zustellung: 8,00 EUR</li>
        <li>Chiffre mit Zustellung per Chat: kostenlos</li>
      </ul>
      <span>
      <span class="font-bold">*Anmerkung:</span> ein etwaiger Chiffre-Anteil wird separat berechnet.
    </span>
    </div>
    <kaz-button class="md:hidden" text="Info schließen" buttonType="cookies" (onClick)="closeAdInfo()"></kaz-button>
    <kaz-button class="hidden md:block  md:self-center" text="Info schließen" width="343px" buttonType="cookies"
                (onClick)="closeAdInfo()"></kaz-button>
  </div>
</div>
