<div *ngIf="chat !== null && ad !== null && message !== null && messages !== null"
     class="md:hover:bg-black/5 md:hover:rounded-[15px] md:cursor-pointer">
  <div class="grid grid-cols-[56px_1fr] py-4 gap-4 md:px-4"
       [class]="chat.id === active?.id ? 'md:bg-[#FDF2E7] md:border-l-[3px] md:border-[#F0811A]' : 'md:bg-[#FAFAFA]'"
       (click)="openChat()">
    <!-- Image -->
    <div class="relative h-14 w-14 md:cursor-pointer">
      <img class="w-auto h-full object-cover rounded-[6px]" [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)"
           alt="Bild">
    </div>
    <!-- Chat-Info -->
    <div class="flex flex-col">
      <div class="grid grid-cols-[1fr_auto] gap-4 mb-0.5">
        <span class="text-[#161D26] text-sm font-semibold line-clamp-1">{{ad.title}}</span>
        <span class="text-xs text-[#5088D6] font-medium">{{message.created | date:'EEE dd.MM.yyyy'}}</span>
      </div>
      <span class="text-[#999999] text-xs line-clamp-2">{{message.message}}</span>
    </div>
    <!-- Divider -->
    <div class="absolute bottom-0 left-0 right-0 w-full h-[1px] bg-[#F1F1F1]"></div>
  </div>
  <!-- Chat -->
  <div *ngIf="showChat" class="fixed top-0 left-0 right-0 z-20 w-full h-full bg-[#FAFAFA] md:hidden">
    <!-- Header -->
    <div class="absolute top-0 left-0 right-0 grid grid-cols-[auto_1fr] gap-4 h-12 p-3 bg-white shadow items-center">
      <!-- Zurück -->
      <div class="flex w-auto h-full items-center justify-center">
        <fa-icon icon="chevron-left" size="xs"></fa-icon>
        <span class="font-light text-xs text-[#222222]" (click)="showChat = false">Zurück</span>
      </div>
      <!-- Titel -->
      <span class="text-sm text-[#161D26] font-semibold line-clamp-1">{{ad.title}}</span>
    </div>

    <!-- Chat-History -->
    <div #chatHistory class="flex flex-col gap-5 h-full bg-[#FAFAFA] mt-12 mb-[76px] p-4 overflow-y-auto max-h-[85vh]">
      <div *ngFor="let msg of messages">
        <div *ngIf="isOwnMsg(msg.senderUserId)" class="flex justify-end">
          <span
            class="flex py-2 px-4 bg-[#F0811A] w-[70%] text-sm text-white border border-[#F0811A] rounded-t-lg rounded-bl-lg whitespace-pre-wrap">{{msg.message}}</span>
        </div>

        <span *ngIf="!isOwnMsg(msg.senderUserId)"
              class="flex py-2 px-4 bg-[#FEFEFE] w-[70%] text-sm text-[#222222] border border-[#F1F1F1] rounded-t-lg rounded-br-lg whitespace-pre-wrap">{{msg.message}}</span>
      </div>
    </div>

    <!-- Message -->
    <div class="absolute bottom-0 left-0 flex flex-row gap-4 w-full bg-white p-4 items-center">
          <textarea [(ngModel)]="msg"
                    class="px-4 pt-3 h-12 grow leading-6 resize-none text-base text-[#161D26] font-medium border border-[#999999] rounded-[6px] bg-[#F7F7F7]"
                    placeholder="Nachricht"></textarea>
      <fa-icon (click)="sendMessage()" class="flex" icon="paper-plane-top" size="xl"></fa-icon>
    </div>
  </div>
</div>
