import {Component, OnInit} from '@angular/core';
import {User} from "../../../interfaces/user";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../services/auth.service";

@Component({
  selector: 'kaz-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {
  user: User | null = null;
  mailRequested: boolean = false;

  constructor(private _api: ApiService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _auth: AuthenticationService) {
  }

  async ngOnInit() {
    if (this._auth.isAuthenticated())
      await this._router.navigate(['main']);


    this._route.paramMap.subscribe(params => {
      const userId = params.get('id')
      if (userId !== null) {
        this.getUser(userId);
      }
    });
  }

  private async getUser(userId: string) {
    try {
      this.user = await this._api.getOwnUser(userId);
    } catch (err) {
      console.log(err);
    }
  }

  async sendVerifyMail(){
    try {
      if (this.user !== null){
        const data = await this._api.sendVerifyMail(this.user.id);
      }
      this.mailRequested = true;
    } catch (err) {
      console.log(err)
    }
  }

}
