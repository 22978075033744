<div class="fixed flex flex-col top-9 left-0 px-4 pt-12 h-full w-full bg-white">
  <!-- Menu Items -->
  <a (click)="onCloseMenuClick()" class="font-semibold text-xl text-[#161D26] mb-4 leading-6 cursor-pointer" routerLink="/main">Start</a>
  <a (click)="onCloseMenuClick()" class="font-semibold text-xl text-[#161D26] mb-4 leading-6 cursor-pointer" routerLink="/entry/search">Suche</a>
  <a (click)="onCloseMenuClick()" class="font-semibold text-xl text-[#161D26] mb-4 leading-6 cursor-pointer" routerLink="/entry/create">Anzeige aufgeben</a>
  <a (click)="onCloseMenuClick()" *ngIf="_auth.isAuthenticated()" class="font-semibold text-xl text-[#161D26] mb-4 leading-6 cursor-pointer">Nachrichten</a>
  <a (click)="onCloseMenuClick()" class="font-semibold text-xl text-[#161D26] mb-4 leading-6 cursor-pointer" routerLink="/profile">Konto</a>
  <a (click)="onCloseMenuClick()" class="font-semibold text-xl text-[#161D26] mb-4 leading-6 cursor-pointer" routerLink="/faq">FAQ</a>
  <!-- Divider -->
  <div class="w-full bg-[#EFEFEF] h-[1px] my-8"></div>

  <span *ngIf="!_auth.isAuthenticated()" class="font-medium text-base text-[#161D26] mb-4 leading-6 cursor-pointer">
    <a class="text-[#F0811A]" routerLink="/login">Anmelden</a>
    oder
    <a class="text-[#F0811A]" routerLink="/signup">Konto erstellen</a>
  </span>
  <div *ngIf="_auth.isAuthenticated()" class="flex flex-col">
    <a class="text-[#F0811A] text-base font-medium" routerLink="/login" (click)="logout()">Abmelden</a>
    <span class="text-xs text-[#A7A7A7] font-normal">{{mail}}</span>
  </div>
</div>
