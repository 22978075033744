import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'kaz-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {


  constructor(private _auth: AuthenticationService,
              private _router: Router) {
  }

  ngOnInit() {
    this.isAuthenticated();
  }

  isAuthenticated() {
    if (this._auth.isAuthenticated()) {
      this._router.navigate(['profile']).then();
    }
  }
}
