import { Component } from '@angular/core';

@Component({
  selector: 'kaz-divider',
  templateUrl: './kaz-divider.component.html',
  styleUrls: ['./kaz-divider.component.scss']
})
export class KazDividerComponent {

}
