<kaz-no-frame>
  <div *ngIf="webUser !== null && !webUser.verified" class="flex flex-col mb-4 h-full items-center justify-center">
    <fa-icon class="text-[#D2D2D2] my-20" icon="clock" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
      Ihr Benutzerkonto wird von uns verifiziert.
    </span>
    <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Es geht sofort für Sie weiter.
    </span>
  </div>

  <div *ngIf="webUser !== null && webUser.verified" class="flex flex-col mb-4 h-full items-center justify-center">
    <fa-icon class="text-green-600 my-20" icon="check" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
      Ihr Benutzerkonto wurde verifiziert.
    </span>
    <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Es geht sofort für Sie weiter.
    </span>
  </div>
</kaz-no-frame>
