import { Component } from '@angular/core';

@Component({
  selector: 'kaz-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent {

}
