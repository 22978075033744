export interface Address{
  street: string;
  number: string;
  zip: string;
  city: string;
}

export interface BillingAddress extends Address{
  firstname: string;
  lastname: string;
  bank: string;
  iban: string;
}

export function emptyBillingAddress() : BillingAddress{
  return {
    firstname:'',
    lastname:'',
    street:'',
    number:'',
    zip:'',
    city:'',
    bank:'',
    iban:'',
  }
}
