<kaz-default-frame>
  <div *ngIf=" webUser !== null && isNotExpired()" class="flex flex-col gap-4 md:max-w-[488px] md:m-auto">
    <span class="text-xl text[#161D26] leading-7 font-semibold">Hallo {{ webUser.firstname + ' ' + webUser.lastname }},</span>
    <span class="text-xs text[#161D26] mb-11 md:mb-16">
      Setzen Sie hier Ihr Passwort zurück
    </span>
    <div class="flex flex-col gap-y-1.5">
      <kaz-input class="w-full" placeholder="Neues Passwort" [(value)]="password" inputType="password" [showEye]="true"></kaz-input>

      <span class="flex text-[#161D26] text-[12px] font-medium mx-4">
          <ng-container *ngIf="!uppercaseRegex.test(password)">{{ 'Mindestens 1 Großbuchstabe, ' }}</ng-container>
          <ng-container *ngIf="!digitRegex.test(password)">{{ 'eine Zahl, ' }}</ng-container>
          <ng-container *ngIf="!specialCharRegex.test(password)">{{ 'ein Sonderzeichen, ' }}</ng-container>
          <ng-container *ngIf="!minLengthRegex.test(password)">{{ 'mind. 8 Zeichen.' }}</ng-container>
        </span>
    </div>
    <kaz-input class="w-full" placeholder="Neues Passwort wiederholen" [(value)]="repeatPassword" inputType="password"
               [showEye]="true"></kaz-input>
    <kaz-button (onClick)="resetPassword()" class="self-center" width="343px"
                [disabled]="isDisabled()"
                text="Zurücksetzen"></kaz-button>
  </div>

  <div *ngIf="!isNotExpired() && webUser !== null" class="flex flex-col mb-4 h-full items-center justify-center">
    <fa-icon class="text-[#F0811A] my-20" icon="hourglass-end" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
      Ihr Zeitraum zum Zurücksetzen ist abgelaufen.
    </span>
    <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Bitte setzen Sie Ihr Passwort bei Bedarf erneut zurück.
    </span>
  </div>

  <div *ngIf="showResetSucceeded" class="flex flex-col mb-4 h-full items-center justify-center">
    <fa-icon class="text-green-600 my-20" icon="check" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7] mb-4">
      Password erfolgreich zurückgesetzt.
    </span>
    <span class="mx-8 text-center font-medium text-base text-[#A7A7A7]">
      Es geht sofort für Sie weiter.
    </span>
  </div>

</kaz-default-frame>
