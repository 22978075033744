import {Component, OnInit} from '@angular/core';
import {User} from "../../../interfaces/user";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'kaz-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  webUser: User | null = null;
  token: string = '';

  password: string = '';
  repeatPassword: string = '';

  // Regex
  uppercaseRegex = /[A-Z]/;
  digitRegex = /\d/;
  specialCharRegex = /[!?=|@#$%^()&*:;.,_+-]/;
  minLengthRegex = /.{8,}/

  showResetSucceeded: boolean = false;

  constructor(private _route: ActivatedRoute,
              private _api: ApiService,
              private _router: Router,
  ) {
  }

  async ngOnInit() {
    this._route.paramMap.subscribe(params => {
      const id = params.get('id')!;
      const token = params.get('token')!;

      if (id !== null && token !== null) {
        this.getWebUser(id).then();
        this.token = token;
      }
    });
  }

  async getWebUser(id: string) {
    try {
      this.webUser = await this._api.getOwnUser(id);

      if (this.webUser !== null && !this.isNotExpired()){
        setTimeout(() => {
          this._router.navigate(['login']).then();
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  isNotExpired() {
    if (this.webUser !== null) {
      const today = new Date();
      const expiryDate = new Date(this.webUser.resetPasswordExpiryDate.toString())

      return today < expiryDate;
    }
    return false;
  }

  async resetPassword() {
    try {
      if (this.token.length > 0 && this.webUser !== null){
        const user = await this._api.resetPassword(this.webUser.id, this.token, this.password);
        if (user !== null){
          this.showResetSucceeded = true;
          this.webUser = null;
          setTimeout(() => {
            this._router.navigate(['login']).then();
          }, 4000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  isDisabled() {
    return !(this.password.length > 7 &&
      this.repeatPassword === this.password &&
      /[A-Z]/.test(this.password) &&      // Mindestens einen Großbuchstaben
      /\d/.test(this.password) &&         // Mindestens eine Zahl
      /[!?=|@#$%^()&*:;.,_+-]/.test(this.password));
  }
}
