import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {Ad} from "../../../interfaces/ad";
import {Location} from "@angular/common";

@Component({
  selector: 'kaz-edit-ad',
  templateUrl: './edit-ad.component.html',
  styleUrls: ['./edit-ad.component.scss']
})
export class EditAdComponent implements OnInit {
  ad: Ad | null = null;
  updatedAd: Partial<Ad> | null = null;
  price: string = "";

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _api: ApiService,
              private _location: Location) {
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      const adId = params['id']!;
      this.getAd(adId).then();
    });
  }

  async getAd(id: string) {
    try {
      if (id !== '') {
        this.ad = await this._api.getAd(id);
        this.price = String(this.ad.price);
      }
    } catch (err) {

    }
  }

  async updateAd() {
    try {
      if (this.ad !== null) {
        this.updatedAd = {
          id: this.ad.id,
          category: this.ad.category,
          adType: this.ad.adType,
          title: this.ad.title,
          description: this.ad.description,
          priceType: this.ad.priceType,
          price: +this.price,
        };
        const data = await this._api.updateAd(this.updatedAd);
        this._router.navigate(['profile']).then();
      }
    } catch (err) {
      console.log(err);
    }
  }

  onAdTypeChange(typ: 'OFFER' | 'WANTED' | 'GOOD_DEED') {
    if (this.ad !== null) {
      this.ad.adType = typ;
      this.ad.price = 0;
      this.ad.publicationDate = [];
      this.ad.cipher.type = 'NONE';
      this.ad.category.subName = '';
    }
  }

  onAdPriceTypeChange(typ: 'FP' | 'VHB') {
    if (this.ad !== null) {
      this.ad.priceType = typ;
    }
  }

  isFormIncomplete(): boolean {
    if (this.ad !== null)
      return (
        this.ad.title === "" ||
        this.ad.description === ""
      );
    else
      return true;
  }

  formatInput(event: any) {
    // Entfernen alle Nicht-Ziffern aus Eingabe
    const input = event.target.value.replace(/[^0-9]/g, '');
    if (input == '')
      this.price = "0";
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      return;
    }

    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  checkPrice(event: any) {
    if (event.target.value === "0")
      this.price = "";
  }

  goBack() {
    this._location.back();
  }

}
