<div class="relative flex flex-row items-center">
  <input class="grow h-10 pl-11 text-xs font-medium border border-[#D2D2D2] rounded-full shadow"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         (keydown.enter)="search(value)"
  >
  <fa-icon class="absolute left-4" icon="magnifying-glass" size="sm"></fa-icon>
  <fa-icon (click)="showFilter()" class="absolute right-4 md:hidden" icon="filter" size="sm"></fa-icon>
  <div (click)="search(value)"
    class="hidden rounded-full bg-[#224361] h-10 ml-4 px-11 text-sm text-white font-semibold md:cursor-pointer md:flex md:flex-row md:gap-2 md:items-center md:justify-center">
    <fa-icon icon="magnifying-glass" size="sm"></fa-icon>
    <span>Finden</span>
  </div>
</div>
