import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'kaz-input',
  templateUrl: './kaz-input.component.html',
  styleUrls: ['./kaz-input.component.scss']
})
export class KazInputComponent {
  @Input('value') value: string = "";
  @Output('valueChange') valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() width: string = "100%";
  @Input() height: string = "3rem";
  @Input() placeholder: string = "Tippe etwas ein";
  @Input() title: string = "";
  @Input() inputType: 'text' | 'password' | 'number' = 'text';
  @Input() maxLength: number | undefined = undefined;
  @Input() showEye: boolean = false;
  @Input() capitalizeFirstLetter: boolean = false;


  showPassword: boolean = false;

  onInputChange(): void {
    if (this.inputType === 'text' && this.capitalizeFirstLetter){
      this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);
    }
    this.valueChange.emit(this.value);
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.inputType === 'number') {
      const inputChar = event.key;

      if (inputChar === 'Backspace') {
        return;
      }

      if (!/^[0-9]$/.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  changeInputType() {
    this.showPassword = !this.showPassword;
    if (this.showPassword)
      this.inputType = 'text';
    else
      this.inputType = 'password';
  }
}
