<kaz-default-frame>
  <div class="relative flex flex-col w-full items-center mt-5 md:mt-0">
    <!-- ProfileInfo -->
    <div class="flex flex-col w-full items-center md:flex-row md:gap-4">
      <!-- Back -->
      <div *ngIf="!isActive('Anzeigen')"
           class="hidden md:absolute md:top-0 md:left-[-90px] md:flex md:flex-row md:gap-0.5 md:w-[58px] md:text-xs md:text-[#222222] md:justify-center md:cursor-pointer"
           (click)="setAsActive('Anzeigen')">
        <fa-icon icon="chevron-left" size="sm"></fa-icon>
        <span>Zurück</span>
      </div>
      <div class="flex justify-center items-center h-16 w-16 rounded-full text-white bg-[#F0811A]">
        <span class="font-normal text-xl tracking-wider">{{ getInitials() }}</span>
      </div>
      <div class="flex flex-col items-center md:items-start">
        <span class="mt-1 text-base text-[#222] font-medium">{{ profile?.firstname }} {{ profile?.lastname }}</span>
        <span class="text-xs text-[#A7A7A7] font-normal leading-4">{{ profile?.mail }}</span>
        <span
          class="text-xs text-[#5088D6] font-medium leading-4 mt-1.5">Aktiv seit {{ profile?.created | date }}</span>
      </div>
      <div
        class="hidden md:flex md:flex-row md:grow md:gap-8 md:text-base md:text-[#224361] md:font-medium md:justify-end">
        <div
          class="flex flex-row gap-2 w-[185px] py-1.5 border border-[#224361] justify-center rounded-full cursor-pointer"
          (click)="setAsActive('Archiv')"
          [class]="!isActive('Archiv') ? 'bg-white text-[#224361]' : 'bg-[#224361] text-white'">
          <fa-icon icon="box-archive" size="sm"></fa-icon>
          <span>Archiv</span>
        </div>
        <div
          class="flex flex-row gap-2 w-[185px] py-1.5 border border-[#224361] justify-center rounded-full cursor-pointer"
          (click)="setAsActive('Nachrichten')"
          [class]="!isActive('Nachrichten') ? 'bg-white text-[#224361]' : 'bg-[#224361] text-white'">
          <fa-icon icon="envelope" size="sm"></fa-icon>
          <span>Nachrichten</span>
        </div>
        <div
          class="hidden flex-row gap-2 w-[185px] py-1.5 border border-[#224361] justify-center rounded-full cursor-pointer"
          (click)="setAsActive('Favoriten')"
          [class]="!isActive('Favoriten') ? 'bg-white text-[#224361]' : 'bg-[#224361] text-white'">
          <fa-icon icon="heart" size="sm"></fa-icon>
          <span>Favoriten</span>
        </div>
      </div>
    </div>
    <div class="hidden md:flex md:h-2 md:w-full md:bg-[#FAFAFA] md:mt-10 md:mb-7"></div>

    <!--  Navigation Bar  -->
    <ul class="flex flex-row w-full border-b border-[#D2D2D2] text-base font-medium h-12 mt-1.5 md:hidden">
      <li *ngFor="let tab of navBar" (click)="setAsActive(tab.name)"
          class="flex grow h-12 justify-center items-center transition-colors duration-300"
          [style.border-bottom]="tab.current?'1px solid #222222':'0px'"
          [style.color]="tab.current?'#222222':'#D2D2D2'">{{ tab.name }}
      </li>
    </ul>
    <kaz-ads *ngIf="isActive('Anzeigen')" class="w-full"></kaz-ads>
    <kaz-messages *ngIf="isActive('Nachrichten')" class="w-full"></kaz-messages>
    <kaz-archive *ngIf="isActive('Archiv')" class="w-full"></kaz-archive>
    <!--    <kaz-favorites class="w-full" [hidden]="isActive('Favoriten')"></kaz-favorites>-->
  </div>
</kaz-default-frame>
