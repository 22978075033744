import {Component, OnInit} from '@angular/core';
import {Ad} from "../../../interfaces/ad";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {CursorToken} from "../../../interfaces/utils";

@Component({
  selector: 'kaz-search-ad',
  templateUrl: './search-ad.component.html',
  styleUrls: ['./search-ad.component.scss']
})
export class SearchAdComponent implements OnInit {
  ads: Ad[] = [];

  pageSize: number = 24;
  search: string = '';
  nextToken: CursorToken = "@start";
  showEmpty: boolean = false;
  loadingAds: boolean = false;

  constructor(private _route: ActivatedRoute,
              private _api: ApiService
  ) {
  }

  async ngOnInit() {
    this._route.queryParams.subscribe(params => {
      this.search = params['search'] ?? '';
      this.getSearchedAd().then();
    });
  }

  async getSearchedAd() {
    try {
      this.loadingAds = true;
      const data = await this._api.getSearchedAdList(this.search, '@start', this.pageSize);
      this.ads = data.ads;
      this.nextToken = data.nextPageToken;
      if (this.ads.length <= 0) this.showEmpty = true;
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingAds = false;
    }
  }

  async loadMore() {
    try {
      this.loadingAds = true;
      const data = await this._api.getSearchedAdList(this.search, this.nextToken, this.pageSize);
      this.ads = [...this.ads, ...data.ads];
      this.nextToken = data.nextPageToken;
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingAds = false;
    }
  }
}
