import {Component, OnInit} from '@angular/core';
import {Ad} from "../../interfaces/ad";
import {ApiService} from "../../services/api.service";
import {clearLastSeen, getLastSeen, setLastSeen} from "../../utils/sessionStorage";
import {CursorToken} from "../../interfaces/utils";

@Component({
  selector: 'kaz-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  lastSeenIds: string[] = [];
  lastSeen: Ad[] = [];
  recommended: Ad[] = [];

  adsPageSize: number = 24;
  nextToken: CursorToken = "@start";
  loadingAds: boolean = false;

  constructor(private _api: ApiService) {
  }

  async ngOnInit() {
    await this.getAdList();
    await this.getLastSeen();
  }

  async getAdList() {
    try {
      this.loadingAds = true;
      const data = await this._api.getAdList('@start', this.adsPageSize);
      this.recommended = data.ads;
      this.nextToken = data.nextPageToken;
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingAds = false;
    }
  }

  async loadMoreRecommends() {
    try {
      this.loadingAds = true;
      const data = await this._api.getAdList(this.nextToken, this.adsPageSize);
      this.recommended = [...this.recommended, ...data.ads];
      this.nextToken = data.nextPageToken;
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingAds = false;
    }
  }

  async getLastSeen() {
    this.lastSeenIds = getLastSeen();
    try {
      if (this.lastSeenIds.length > 0) {
        const lastSeenIdsString = this.lastSeenIds.join(',');
        this.lastSeen = await this._api.getLastSeen(lastSeenIdsString);
        clearLastSeen();
        this.lastSeen.forEach( item => {
          setLastSeen(item.id);
        })
      }
    } catch (err) {
      console.error(err);
    }
  }

  hasValidatedAd(): boolean {
    return this.lastSeen.some((item) => item.validated && !item.reserved);
  }

}
