<div *ngIf="ownAds !== null" class="flex flex-col">
  <div *ngIf="ownAds.ads.length === 0"  class="flex flex-col mb-7">
    <img class="mx-16 mt-16" src="assets/images/404.svg" alt="404-Logo">
    <span class="mx-[84px] mt-8 font-medium text-base text-[#A7A7A7] text-center">Bisher noch ziemlich<br>leer hier.</span>
  </div>
  <kaz-button class="text-[#224361] mt-5 md:hidden" routerLink="/entry/create" buttonType="bordered" text="Anzeige aufgeben"></kaz-button>
  <!-- Divider -->
  <div class="my-5 border-t border-[#EFEFEF] md:hidden"></div>
  <span *ngIf="ownAds.ads.length > 0" class="hidden md:flex md:text-xl md:text-[#222222] md:font-semibold md:mb-7">Archivierte Anzeigen</span>
  <div class="flex flex-col gap-2 md:grid md:grid-cols-2 md:gap-[72px]">
    <div *ngFor="let ad of ownAds.ads">
      <kaz-card-archive-ads [ad]="ad"></kaz-card-archive-ads>
    </div>
  </div>
</div>
