import {Component, Input} from '@angular/core';
import {slideAnimation} from "../../animations/slide.animations";

interface Item {
  active: boolean,
  header: string,
  data: string,
}

@Component({
  selector: 'kaz-collapse',
  templateUrl: './kaz-collapse.component.html',
  styleUrls: ['./kaz-collapse.component.scss'],
  animations: [slideAnimation],
})
export class KazCollapseComponent {
  @Input() item: Item | null = null;

  constructor() {
  }
}
