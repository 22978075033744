<div class="flex flex-row gap-2 pb-4 overflow-x-auto max-w-full font-medium text-sm text-[#D2D2D2] whitespace-nowrap snap-x">
  <div *ngIf="!isLoggedIn()" class="border border-[#D2D2D2] rounded-full px-6 py-1 snap-center" routerLink="/login">
    Anmelden
  </div>
  <div class="border border-[#D2D2D2] rounded-full px-6 py-1 snap-center" routerLink="/entry/create">
    Anzeige Aufgeben
  </div>
  <div class="hidden border border-[#D2D2D2] rounded-full px-6 py-1 snap-center">
    Alle Kategorien
  </div>
  <div class="hidden border border-[#D2D2D2] rounded-full px-6 py-1 snap-center">
    Favoriten
  </div>
</div>
