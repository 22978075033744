<div *ngIf="item !== null" class="flex flex-col w-full">
  <!-- Header -->
  <div (click)="item.active = !item.active" class="grid grid-cols-[1fr_auto] text-sm text-[#222222] font-semibold bg-[#FAFAFA] p-4 justify-center md:cursor-pointer">

    <span>{{item.header}}</span>
    <fa-icon [icon]="item.active ? 'chevron-up' : 'chevron-down' "></fa-icon>
  </div>
  <!-- Body -->
  <div *ngIf="item.active" [@slide]="item.active" class="text-sm text-[#222222] whitespace-pre-wrap p-4">
    <span>{{item.data}}</span>
  </div>
  <div class="bg-[#D2D2D2] h-[1px] w-full"></div>
</div>
