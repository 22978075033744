import {Component, Input} from '@angular/core';
import {Ad} from "../../interfaces/ad";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
  selector: 'kaz-card-default',
  templateUrl: './kaz-card-default.component.html',
  styleUrls: ['./kaz-card-default.component.scss']
})
export class KazCardDefaultComponent {
  @Input() ad: Ad | null = null;
  protected readonly getDummyPicture = getDummyPicture;
}
